<div class="row">
    <div class="col-lg-5">
        <div class="h-100">
            <img src="../assets/images/Policy-Development.jpg" alt="no img" class="w-100 h-100">
        </div>
    </div>
    <div class="col-lg-7">
        <div class="d-flex align-items-center h-100">
            <div class="inner-section">
                <h1>Knowledge Sharing</h1>
                <!--h1>Knowledge Sharing of Good Practices & Innovation</h1-->
                <p class="my-3">To get an overview of global, regional, and country-specific data and resources,
                    including innovative initiatives, good practices, and lessons learned from policy and program
                    perspectives. It also offers quick links to relevant portals and platforms.</p>
                <div class="btn-group d-inline">
                    <div class="row">
                        <div class="col-lg-6">
                            <button class="active w-100 position-relative custom-tooltip_container"
                                [routerLink]="['../knowledge-sharing/good-policy-practices', knowledgeSharingSectionConstant.Good_Policy_Practices]">Good
                                Practices
                                <span class="custom-tooltip_white">View good practices and lessons learned submitted by
                                    HQ,
                                    regional and country offices.</span>
                            </button>
                        </div>
                        <div class="col-lg-6">
                            <a href="{{landingPageInnovativeInitiative}}" target="_self"><button
                                    class="active w-100 position-relative custom-tooltip_container">Innovative
                                    initiatives
                                    <span class="custom-tooltip_white">View innovative initiatives submitted by HQ,
                                        regional
                                        and
                                        country offices.</span>
                                </button></a>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-4">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown">

                                    <button
                            class="dropdown-toggle inrButtonSubmit position-relative w-100 custom-tooltip_container submit-here" role="button" data-bs-toggle="dropdown" aria-expanded="true">Submit
                            Practices Here
                            <span class="custom-tooltip_white">
                                (1) Innovative Initiatives<br />(2) Good Practices and Lessons Learned
                            </span>
                        </button>
                                <ul class="dropdown-menu">
                                    <li><a href="{{countryUploadTemplateLink}}" target="_blank" class="dropdown-item" role="button">Good Practices</a></li>
                                    <li><a href="{{innovativeInitiativesFormLink}}" target="_blank" class="dropdown-item" role="button">Innovative Initiatives</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-8">
                        <div class="input-group input-item w-100">
                            <span id="virtual-addon1"> Country <i class="ps-2">|</i> </span>
                            <input type="text" id="searchCountry" class="input-text"
                                placeholder="Type first 3 letters of country name" aria-label="Username"
                                (keydown.enter)="navigateToPdad()" aria-describedby="basic-addon1" [(ngModel)]="searchText"
                                #search (keyup)="displayPopup()">
                            <div class="search-country position-absolute">
                                <kendo-popup [anchor]="search" *ngIf="showPopup" popupClass="inner-wrapper"
                                    class="country-popup" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign">
                                    <div *ngIf="!loading" class="content">
                                        <div *ngFor="let country of searchCountries;let j=index"
                                            (click)="selectCountry(country)"
                                            class="country-fil-col d-flex justify-content-between align-items-center">
                                            <p class="mb-0"> {{ country.countryName }}</p>
                                        </div>
                                        <div *ngIf="searchText.trim() != '' && searchText.length > 2 && searchCountries.length == 0 "
                                            class="country-fil-col d-flex justify-content-between align-items-center">
                                            <span class="no-match box-shadow-none dec-action">We didn't find any
                                                matches.</span>
                                        </div>
                                    </div>
                                </kendo-popup>
                            </div>
                            <span [routerLink]="['../_pdad/pdad-country', sectionConstant.Country,selectedCountryCode]"
                                id="virtual-addon2" [ngClass]="{'disabled' : selectedCountry ==  null}" tabindex="0"><i
                                    class="fas fa-search"></i></span>
                        </div>
                    </div>

                    <!--div class="col-lg-4">
                    <a target="_blank"
                        href="https://apps.powerapps.com/play/e/default-1588262d-23fb-43b4-bd6e-bce49c8e6186/a/c88bde71-1b53-4b6f-b5ab-371ef247b8c1?myFT=gp&tenantId=1588262d-23fb-43b4-bd6e-bce49c8e6186&hint=e4ad6366-ca47-4430-bd27-d1fa9908a0fc&sourcetime=1701174253263">
                        <button
                            class="position-relative w-100 custom-tooltip_container submit-here">Submit
                            Practices Here
                            <span class="custom-tooltip_white">
                                (1) Innovative Initiatives<br />(2) Good Practices and Lessons Learned
                            </span>
                        </button></a>
                    </div>
                    <div class="col-lg-8">
                        <div class="input-group input-item w-100">
                            <span id="virtual-addon1"> Country <i class="ps-2">|</i> </span>
                            <input type="text" id="searchCountry" class="input-text"
                                placeholder="Type first 3 letters of country name" aria-label="Username"
                                (keydown.enter)="navigateToPdad()" aria-describedby="basic-addon1" [(ngModel)]="searchText"
                                #search (keyup)="displayPopup()">
                            <div class="search-country position-absolute">
                                <kendo-popup [anchor]="search" *ngIf="showPopup" popupClass="inner-wrapper"
                                    class="country-popup" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign">
                                    <div *ngIf="!loading" class="content">
                                        <div *ngFor="let country of searchCountries;let j=index"
                                            (click)="selectCountry(country)"
                                            class="country-fil-col d-flex justify-content-between align-items-center">
                                            <p class="mb-0"> {{ country.countryName }}</p>
                                        </div>
                                        <div *ngIf="searchText.trim() != '' && searchText.length > 2 && searchCountries.length == 0 "
                                            class="country-fil-col d-flex justify-content-between align-items-center">
                                            <span class="no-match box-shadow-none dec-action">We didn't find any
                                                matches.</span>
                                        </div>
                                    </div>
                                </kendo-popup>
                            </div>
                            <span [routerLink]="['../_pdad/pdad-country', sectionConstant.Country,selectedCountryCode]"
                                id="virtual-addon2" [ngClass]="{'disabled' : selectedCountry ==  null}" tabindex="0"><i
                                    class="fas fa-search"></i></span>
                        </div>
                    </div-->

                </div>
                    <!-- <button class="position-relative custom-tooltip_container"
                        [routerLink]="['../_pdad/migrant-governance-indicators', sectionConstant.Migrant_Governance_Indicators]">MGI
                        <span class="custom-tooltip_white">Migration
                            Governance Indicators</span>
                    </button>
                    <a target="_blank" href="https://worldmigrationreport.iom.int/"><button
                            class="position-relative custom-tooltip_container">WMR
                            <span class="custom-tooltip_white">World Migration
                                Report</span>
                        </button></a>
                    <button class="position-relative custom-tooltip_container"
                        [routerLink]="['../_pdad/policy-development-cycle', sectionConstant.Policy_Development_Cycle]">PDC
                        <span class="custom-tooltip_white">Policy
                            Development Cycle</span>
                    </button>
                    <button class="position-relative custom-tooltip_container"
                        [routerLink]="['../_pdad/good-policy-approaches', sectionConstant.Good_Policy_Approaches]">GPA
                        <span class="custom-tooltip_white">Good
                            Policy Approaches</span>
                    </button>
                    <a target="_blank" href="https://gmdac.iom.int/"><button
                            class="position-relative custom-tooltip_container">GMDAC Data Portal
                            <span class="custom-tooltip_white">GMDAC Data Portal</span>
                        </button></a> -->
                </div>
                <!-- <div>
                    <ul class="d-flex flex-wrap list-migration">
                        <li><a target="_blank" href="https://policyrepository.iom.int/">MPR</a></li>
                        <li><a target="_blank" href="https://dtm.iom.int/">DTM</a></li>
                        <li><a target="_blank" href="https://evaluation.iom.int/evaluation-search-pdf">M&E
                                Repository</a></li>
                        <li><a target="_blank" href="https://migrationnetwork.un.org/hub">Migration Network Hub</a></li>
                        <li><a target="_blank" href="https://crisisresponse.iom.int/">Global Crisis Response Portal</a>
                        </li>
                    </ul>
                </div> -->
                <!-- <div>
                    <ul class="d-flex flex-wrap list-migration">
                        <li><a target="_blank"
                                href="https://www.iom.int/sites/g/files/tmzbdl486/files/about-iom/migof_brochure_a4_en.pdf">Migration
                                Governance Frame work</a></li>
                        <li><a target="_blank" href="https://www.un.org/sustainabledevelopment/">Sustainable Development
                                Goals</a></li>
                        <li><a target="_blank" href="https://migrationnetwork.un.org/hub">Migration Network Hub</a></li>
                        <li><a target="_blank" href="https://imldb.iom.int/">International Migration Law Database</a>
                        </li>
                        <li><a target="_blank"
                                href="https://emergencymanual.iom.int/entry/15815/ioms-humanitarian-policy-principles-for-humanitarian-action-pha">Humanitarian
                                Policy Portal</a></li>
                        <li><a target="_blank"
                                href="https://environmentalmigration.iom.int/tags/environmental-policy">Environment
                                Policy Portal</a></li>
                        <li><a target="_blank"
                                href="https://iomint.sharepoint.com/sites/IOMMigrationandSustainableDevelopment?xsdata=MDN8MDF8fDU1NWQ1N2QxZjNjMjQ1YTdhN2JjY2I1MmI4NWMyZmFmfDE1ODgyNjJkMjNmYjQzYjRiZDZlYmNlNDljOGU2MTg2fDF8MHw2Mzc3MDc2NDQwMjU1NzgzNDF8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaUlpd2lRVTRpT2lJaUxDSlhWQ0k2TVRKOQ%3D%3D&sdata=NXhHaGs5Y0R4aG40bE4wc2pRb01RRVZzbzJYTjBMb2hxWHNrQmFIaE8wND0%3D&ovuser=1588262d-23fb-43b4-bd6e-bce49c8e6186%2Csmaseeh%40iom.int&OR=Teams-HL&CT=1635167627870">Migration
                                & Sustainable Development</a></li>
                        <li><a target="_blank"
                                href="https://iomint.sharepoint.com/sites/TRDTeamSite2/SitePages/Humanitarian-Development-Peace-Nexus.aspx?xsdata=MDN8MDF8fDY1N2NiN2RjZjU4YTQyY2Q5NTk0YzliZjVjMjc3MmQwfDE1ODgyNjJkMjNmYjQzYjRiZDZlYmNlNDljOGU2MTg2fDF8MHw2Mzc3MTYxOTAzNzAyMTIxMzl8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaUlpd2lRVTRpT2lJaUxDSlhWQ0k2TVRKOQ%3D%3D&sdata=cC9Vcjl3R0tYSmZQTEx5enozc2pSMVlCZVVkdmtuRVY4K2Y3VEE3YUVlND0%3D&ovuser=1588262d-23fb-43b4-bd6e-bce49c8e6186%2Csmaseeh%40iom.int">Humanitarian-Development-Peace
                                Nexus</a></li>
                        <li><a target="_blank" href="https://returnandreintegration.iom.int/en">Return &
                                Reintegration</a></li>
                        <li><a target="_blank"
                                href="https://migrationhealthresearch.iom.int/iom-resettlement-un-migration-agency">Resettlement
                                Management Portal</a></li>
                        <li><a target="_blank" href="https://emergencymanual.iom.int">Emergency Manual</a></li>
                        <li><a target="_blank" href="https://www.iom.int/labour-mobility-and-human-development">LHD
                                Portal</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>