import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { MsalUserService } from '../../common/services/msal-user.service';
import { Roles } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {

  constructor(private httpData: HttpClient, private userService: MsalUserService) { }


  getMenuItems() {
    const userRoles = this.userService.getUserRoles();
    //let url = environment.apiBaseUrl + '/api/MenuItems';
    // debugger
    // let roleNames = new HttpParams();
    // roleNames = roleNames.appendAll({ 'roleNames': userRoles });

    // return this.httpData.get(url, { params: roleNames });
    
    let url = environment.apiBaseUrl + "api/MenuItems/" + userRoles ;
    return this.httpData.get(url);
  }

  getUserRolePermissionAction(): Observable<any> {
    const userRoles = this.userService.getUserRoles() as Roles[];
    return this.getPermissionAction(userRoles);
  }

  getPermissionAction(userRoles: Roles[]): Observable<any> {
    let url = environment.apiBaseUrl + 'api/PermissionAction';

    let headers: HttpHeaders;
    headers = new HttpHeaders({ 'cache-response': 'true' });

    let roleNames = new HttpParams();
    roleNames = roleNames.appendAll({ 'roleNames': userRoles });

    return this.httpData.get(url, { headers: headers, params: roleNames });

  }

}
