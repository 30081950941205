import { Component, OnInit } from '@angular/core';
import { FeaturedGoodPracticesService } from 'src/app/services/featured-good-practices/featured-good-practices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-featured-good-practices',
  templateUrl: './featured-good-practices.component.html',
  styleUrls: ['./featured-good-practices.component.css']
})
export class FeaturedGoodPracticesComponent implements OnInit {
  gpRecords: any;
  showModal: boolean;
  gpsTitle: any;
  gpDescription: any;
  gpDate: any;
  gpRecordsById: any;
  gpLink: any;
  responsiveOptions;
  public appendToUrl = environment.azblobSecurityUrl;
  constructor(private featuredGoodPracticesService: FeaturedGoodPracticesService) { 
    this.responsiveOptions = [{
      breakpoint: '1024px',
      numVisible: 1,
      numScroll: 1
    }];
  }

  ngOnInit() {
    this.featuredGoodPracticesService.GetFeaturedGoodPracticesService().subscribe(data => {
      this.gpRecords = data;
      console.log(this.gpRecords)
    })
  }

  hide() {
    this.showModal = false;
  }

  showGoodPractice(gpId) {
    this.gpRecordsById = this.gpRecords.find(c => c.gpId === gpId);
    this.showModal = true;
    this.gpsTitle = this.gpRecordsById.gpsTitle;
    this.gpDescription = this.gpRecordsById.gpDescription;
    this.gpDate = this.gpRecordsById.gpDate;
    this.gpLink = this.gpRecordsById.gpLink;
  }

  gotoEvent(gpLink) {
    window.open(gpLink, '_blank');
  }

}
