import { Component, HostListener, OnInit } from '@angular/core';
import { Align } from "@progress/kendo-angular-popup";
import {PDADCountryService} from "../../services/PDAD/pdad-country.service"
import { PDADSection } from '../../../app/constants/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { PDADUrl } from 'src/environments/environment';
import { KnowledgeSharingSection } from '../../../app/constants/constants';
import { environment, redirectionUrl } from '../../../environments/environment'

@Component({
  selector: 'app-policy-development-analysis-data',
  templateUrl: './policy-development-analysis-data.component.html',
  styleUrls: ['./policy-development-analysis-data.component.css']
})
export class PolicyDevelopmentAnalysisDataComponent implements OnInit {
  landingPageInnovativeInitiative: any;
  knowledgeSharingSectionConstant: any;
  sectionConstant: any;  
  showMore=false;

  searchText:string='';
  showPopup = false;
  public countries: Array<{ countryName: string; myIOMCountryCode: string; mgiCountryCode: string; iomRegionCode:string }>;
  public searchCountries: Array<{ countryName: string; myIOMCountryCode: string; mgiCountryCode: string; iomRegionCode:string }>;
  public loading = false;
  public selectedCountry :any;
  public selectedCountryCode: any;

  private anchorAlign: Align = { horizontal: "left", vertical: "bottom" };
  private popupAlign: Align = { horizontal: "left", vertical: "top" };

  public myIOMUrlWithParams:string= '';
  public mgiUrlWithParams:string= '';


  private lastYear= new Date().getFullYear() - 1;
  
  public countryUploadTemplateLink = environment.countryUploadTemplateLink;
  public innovativeInitiativesFormLink = environment.innovativeInitiativesFormLink;
  
  @HostListener("document:click", ["$event"])
  public documentClick(event: any): void {  
    this.showPopup = false;
  } 
  
    constructor(private activatedroute:ActivatedRoute, private PDADCountryService: PDADCountryService,
      private router: Router) {
    this.sectionConstant = PDADSection;
    this.knowledgeSharingSectionConstant = KnowledgeSharingSection;
  }

  ngOnInit(): void {
    this.landingPageInnovativeInitiative = environment.landingPageInnovativeInitiative;
    this.PDADCountryService.getCountries().subscribe(countries =>{
      this.countries = countries.filter(c => c.countryName.toLowerCase() != ('Global'.toLowerCase()));
      this.setSelectedCountry();
    })
  }

  private setSelectedCountry(){
    this.activatedroute.params.subscribe(params => {
      var countryName = params.countryName;
      var country = this.countries.find(c => c.countryName == countryName);
      this.selectCountry(country);
      this.loadCountryData();
    });
  }
  public displayPopup() {
    this.showPopup = true;
    this.selectedCountry = null;
    if (this.searchText.length > 2) {
      this.searchCountry();
    } else {
      this.searchCountries = [];
    }
  }

  private searchCountry(){
    this.loading = true;
    var search = this.searchText.toLowerCase();
    this.searchCountries = this.countries.filter(c =>c.countryName.toLowerCase().includes(search));
    if(this.searchCountries.length == 1 && this.isEnterExactCountryName(this.searchCountries[0].countryName)){
      this.selectedCountry = this.searchCountries[0];
    }
    this.loading = false;
  }

  isEnterExactCountryName(countryName){
    return this.searchText.toLowerCase() == countryName.toLowerCase();
  }

  selectCountry(country){
    this.selectedCountry = country;
    this.searchText = country.countryName;
    this.selectedCountryCode = country.myIOMCountryCode;
    document.getElementById("searchCountry").focus();
  }

  togglePdpa(current) {
    this.showMore=!this.showMore;    
   }

   loadCountryData(){
    this.myIOMUrlWithParams = PDADUrl.MyIOMUrl + "?region="+ this.selectedCountry.iomRegionCode +"&country=" + this.selectedCountry.myIOMCountryCode ;
    this.mgiUrlWithParams = PDADUrl.MGIUrl + "?yr="+ this.lastYear +"&ccode=" + this.selectedCountry.mgiCountryCode ;
  }

  navigateToPdad(){
      if(this.selectedCountry !=  null)
      this.router.navigate(['../_pdad/pdad-country', this.sectionConstant.Country, this.selectedCountryCode]);
  }

}
