import { Component, Output, EventEmitter } from '@angular/core';
import { Utils } from '../utils/utils';


export enum DialogResult {
  CANCEL = 0,
  NO = 1,
  YES = 2,
};

@Component({
  selector: 'yes-no-dialog',
  template: `<div class="modal-backdrop fade in" [style.display]="'block'"></div>
                <div class="modal" tabindex="-1" role="dialog" style="display: block" [style.display]="'block'" id="yesNoDialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header" style="background:navy;">
                                <h4 class="modal-title" style="color:white" >{{title || 'Confirm'}}</h4>
                                <button type="button" class="btn-close btn-close-white"   (click)="cancel()" >&times;</button>
                            </div>
                            <br /> <br />
                            <div class="modal-body" style="background:white;">
                            <div *ngIf="showYesNoButton" >
                            {{message || 'Are you sure?'}}
                            </div>
                            <div *ngIf="showMessages">
                                <div *ngFor='let msg of messages'>{{msg}}</div>
                            </div>
                            </div>
                            <div class="modal-footer">
                                <button *ngIf="showYesNoButton" type="button" class="btn btn-new-primary text-uppercase" (click)="yes()" >{{yesButtonName}}</button>
                                <button *ngIf="showYesNoButton" type="button" class="btn btn-outline-primary text-uppercase" [class.a-l]="alignNoButtonToLeft" (click)="no()" >{{noButtonName}}</button>
                                <button *ngIf="showCancelButton" type="button" class="btn btn-default text-uppercase" (click)="cancel()" >{{cancelButtonName}}</button>
                            </div>
                        </div>
                    </div>
                </div>`,
  styles:
    [`
      .a-l{
      float: left;
    }
  `]
})
export class YesNoDialogComponent {
  title: string;
  message: string;
  result: number;
  showModal = true;
  showCancelButton = true;
  showYesNoButton = true;
  messages: string[];
  yesButtonName: string;
  noButtonName: string;
  cancelButtonName: string;
  alignNoButtonToLeft: boolean;

  @Output() resultFired: EventEmitter<any> = new EventEmitter();

  hideCancelButton() {
    this.showCancelButton = false;
  }

  hideYesNoButton() {
    this.showYesNoButton = false;
  }

  yes() {
    this.resultFired.emit(DialogResult.YES);
  }
  no() {
    this.resultFired.emit(DialogResult.NO);
  }
  cancel() {
    this.resultFired.emit(DialogResult.CANCEL);
  }

  showMessages(): boolean {
    return Utils.isNullOrUndefined(this.messages) || this.messages.length <= 0 ? false : true;
  }

}
