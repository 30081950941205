<section class="poem-intro">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 mb-3">
        <div>
          <h1 class="mb-3">What is POEM?</h1>
          <p>{{aboutUsInfo}}</p>
          <!-- <p>The Peer Exchange and learning on Migration (POEM) platform is a collaborative space to facilitate the sharing and learning on policy and programmatic work. The platform seeks to enhance IOM's programmatic and policy capacities and strengthen the culture of knowledge management and sharing among the organization. It covers good practices and lessons learned from programmatic and policy work.</p> -->
          <button><a [routerLink]="['../learn-more/learn-more',{'paramKey': 'missionDesc'}]" target="_blank"
              class="about-link">
              Learn More
            </a></button>
        </div>
      </div>
      <div class="col-lg-6 text-center">
        <img src="assets/images/poem-intro.png" alt="no-img" class="w-100">
      </div>
    </div>
  </div>
</section>