export class Utils {

    static isNullOrUndefined(val: any) {
        return val === undefined || val === null;
    }

    static  isEmptyString(val: string) {
        return val === undefined
        || val === null
        || val.trim() === '';
    }
}