import { Injectable } from '@angular/core';
import {  NotificationService,  NotificationRef} from "@progress/kendo-angular-notification";

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(private notificationService: NotificationService) { }


  showSuccess(successMesage){
   this.showNotification(successMesage,"success" );
  }
  showError(errorMessage){
    this.showNotification(errorMessage,"error" );
}
  private showNotification(message, styleType){
    const notification: NotificationRef = this.notificationService.show({
      content: message,
      animation: { type: "slide", duration: 800 },
      position: { horizontal: "right", vertical: "top" },
      type: { style: styleType, icon: true },
      //closable: true,
      hideAfter : 5000
    });
  }
  
}
