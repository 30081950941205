import { Injectable,ViewContainerRef, ComponentRef,ComponentFactoryResolver, Component } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  componentRef : ComponentRef<LoderComponent>
  private containerRef: ViewContainerRef;

  constructor(private factoryResolver: ComponentFactoryResolver) { }
  
  setViewContainerRef(vcr: ViewContainerRef) {
    this.containerRef = vcr;
  }
  private createLoader(): ComponentRef<LoderComponent> {

    const factory = this.factoryResolver.resolveComponentFactory(LoderComponent);
    const component = factory.create(this.containerRef.injector);

    this.containerRef.insert(component.hostView);
    return component;
  }

  private removeComponent(componentRef: any) {
    this.containerRef.clear();
  }

  public showLoader(){
    this.componentRef = this.createLoader();    
  }
  public hideLoader() {
    this.removeComponent(this.componentRef);
    this.componentRef.destroy();
  }
}


@Component({
  selector: 'loader',
template:`
<div class="k-i-loading"></div> `})
export class LoderComponent { }

