export enum Roles{
  Admin = "AdminUser",
  Moderator = "Moderator",
  IOMUser = "IOMUser",
  WorkspaceMember = "WorkspaceMember",
  NonMember = "NonMember"
}

export enum PrivacyStatus {
  Public = 1,
  Private
}
export enum ApprovalStatus{
  None = 1,
  Approve,
  Reject
}

export enum ActiveStatus {
  Active = 1,
  Inactive
}

export enum PageLevel {
  Country = 1,
  Region = 2,
  Global = 3
}

//VirtualWorkspaceSection values hardcoded to virtual-workspace.component.html
export enum VirtualWorkspaceSection {
  Community_Of_Practice = 'Communities of Practice',
  Request_Workspace = 'Request a Community of Practice',
  My_Workspaces = 'My Community of Practice',
  Find_Workspace = 'Find a Community of Practice'
}

export enum PermissionAction {
  Activate_Workspace = 1,
  Set_Workspace_privacy,
  Approve_People_To_Join_Policy_Hub_Virtual_Workspaces,
  Edit_Workspace_Description,
  Edit_Workspace_Objectives,
  View_Public_Workspace_Content_Tab,
  View_Public_Workspace_Discussion_Tab,
  View_Public_Workspace_Events_Tab,
  View_Private_Workspace_Content_Tab,
  View_Private_Workspace_Discussion_Tab,
  View_Private_Workspace_Events_Tab,
  View_Member_Tab,
  Add_Event,
  Edit_Event,
  Delete_Event,
  View_Admin_Modertor_Admin_Panel,
  View_New_Discussion_Button,
  View_Open_Inteam_Button,
  view_Content_Upload_Button
}

//PDADSection values hardcoded to policy-development-analysis-data.component.html
export enum PDADSection {
  Migrant_Governance_Indicators = 'Migration Governance Indicators',
  Policy_Development_Cycle = 'Policy Development Cycle',
  Good_Policy_Approaches = 'Good Policy Approaches'  ,
  Country = "Country",
  Region = "Region",
  Global = "Global"
}

//KnowledgeSharingSection values hardcoded to knowledge-sharing.component.html
export enum KnowledgeSharingSection {
  Good_Policy_Practices = 'Good Practices & Lessons Learned',
  Peer_to_Peer_Learning = 'Peer to Peer Learning',
  Resources = 'Resources',
  Training = 'Training',
}
