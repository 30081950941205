import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {  

  constructor(private httpData : HttpClient) { }

  getEventsData(id) : Observable<any>{
    if(id==null)
    {
      let url = environment.apiBaseUrl + "/api/event";
      return this.httpData.get(url);
    }
    else
    {
      let url = environment.apiBaseUrl + "/api/event?workspaceid="+ id;
      return this.httpData.get(url);      
    }
  }

  saveEventsData(data):Observable<any>{
    let url = environment.apiBaseUrl + "/api/event";
    return this.httpData.post(url,data);
  }

  updateEventsData(data):Observable<any>{
    let url = environment.apiBaseUrl + "/api/event";
    return this.httpData.put(url,data);
  }

  deleteEventsData(data):Observable<any>{
    let url = environment.apiBaseUrl + "/api/event";    
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data
    };    
    return this.httpData.delete(url, options);          
  }  
}
