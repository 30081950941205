import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enablecookies',
  templateUrl: './enablecookies.component.html',
  styleUrls: ['./enablecookies.component.css']
})
export class EnablecookiesComponent implements OnInit {
  message: string = '';
  constructor() { }

  ngOnInit(): void {
    this.message = "Enable Cookies (Chrome browser)";
  }

}
