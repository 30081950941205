import { Observable } from "rxjs";

export class ImageConversion {
  static convertBlobToBase64(blob: Blob): any {
    return new Observable(observer => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }

  static ArrayBufferToBinary(buffer) {
    var uint8 = new Uint8Array(buffer);
    return uint8.reduce((binary, uint8) => binary + uint8.toString(2), "");

  //      // Convert an array buffer to a string bit-representation: 0 1 1 0 0 0...
  //  var dataView = new DataView(buffer);
  //  var response = "", offset = (8/8); 
  //  for(var i = 0; i < dataView.byteLength; i += offset) {
  //      response += dataView.getInt8(i).toString(2); 
  //  }
  //  return response;

  }
}