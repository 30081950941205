<div class="row">
  <div class="col-lg-6">
    <div class="d-flex align-items-center h-100">
      <div class="inner-section">
        <h1>Resources and Portals</h1>
        <p class="my-3">To promote peer to peer support on different thematic, operational and cross cutting Areas. It
          also offers learning and training resources.</p>
        <div class="btn-group d-inline">
          <!-- <a target="_blank"
            href="https://iomint.sharepoint.com/sites/MultiLangPOC-IKM/poem/SitePages/SubmitGP.aspx"><button
              class="active w-100 position-relative custom-tooltip_container">Submit
              Practices Here
              <span class="custom-tooltip_white">
                (1) Innovative Initiatives<br />(2) Good Practices and Lessons Learned
              </span>
            </button></a> -->
            <button class="active w-100 position-relative custom-tooltip_container"
            [routerLink]="['../knowledge-sharing/resources', sectionConstant.Resources]">Toolkits, Guidelines and Platforms
            <span class="custom-tooltip_white">View toolkits, guidelines, portals developed by IOM.</span>
          </button>

          <div class="row">
            <div class="col-lg-6">
          <a target="_blank"
            href="https://iomint.sharepoint.com/teams/InnovationandKnowledgeManagementWorkingGroup/Shared%20Documents/Forms/AllItems.aspx?ct=1693280452569&or=Teams%2DHL&ga=1&LOF=1&id=%2fteams%2fInnovationandKnowledgeManagementWorkingGroup%2fShared%20Documents%2fGeneral%20KMI%20Working%20Group%2fIOM%20Guide%20to%20Knowledge%20Platforms%2f%28Internal%20use%29%20Guide%20to%20Knowledge%20Platforms%2Epdf&parent=%2fteams%2fInnovationandKnowledgeManagementWorkingGroup%2fShared%20Documents%2fGeneral%20KMI%20Working%20Group%2fIOM%20Guide%20to%20Knowledge%20Platforms"><button
              class="position-relative custom-tooltip_container w-100">Guide to Knowledge Portals
              <span class="custom-tooltip_white">
                View complete guide of various digital knowledge platforms, repositories, databases, and portals in
                IOM.</span>
            </button></a>
          </div>
          <!-- <button class="position-relative custom-tooltip_container"
            [routerLink]="['../knowledge-sharing/resources', sectionConstant.Resources]">Resources and Portals
            <span class="custom-tooltip_white">View toolkits, guidelines, portals developed by IOM.</span>
          </button> -->
          <!-- <button class="position-relative custom-tooltip_container"
            [routerLink]="['../knowledge-sharing/peer-to-peer-learning', sectionConstant.Peer_to_Peer_Learning]">Viva
            Engage / Yammer
            <span class="custom-tooltip_white">Engage with existing Viva Engage communities, connect with staff members
              in the organization</span>
          </button> -->
          <div class="col-lg-6">
          <button class="position-relative custom-tooltip_container w-100"
            [routerLink]="['../knowledge-sharing/training', sectionConstant.Training]">Training
            <span class="custom-tooltip_white">Explore trainings available on i-Learn and E-campus Platforms</span>
          </button>
        </div>
        <div class="col-lg-6">
          <a target="_blank" href="https://migrationnetwork.un.org/hub/experts-database"><button
              class="position-relative custom-tooltip_container w-100">Connect with Experts
              <span class="custom-tooltip_white">
                Connect with migration experts from around the world, covering a wide range of issues on
                migration</span>
            </button></a>
          </div>
          <div class="col-lg-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="h-100">
      <img src="../assets/images/Knowledge-Sharing.jpg" alt="no img" style="width: 100%;
        height: 100%;">
    </div>
  </div>
</div>