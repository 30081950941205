import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PDADCountryService {

  constructor(private httpData: HttpClient) { }

  getCountries(): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/Countries';

    let headers: HttpHeaders;
    headers = new HttpHeaders({ 'cache-response': 'true' });
    
    return this.httpData.get(url, { headers: headers });
  }

  getMigrationProfileData(searchText){
    let url = environment.PDADApiBaseUrl + "/api/MigrationCountry?countryName="+ searchText;
    return this.httpData.get(url);
  }

  getRelatedDocuments(countryID): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/CountryRelatedDocuments'; 
    return this.httpData.get<any>(url + '?CountryID=' + countryID);
  }

  getDocType() : Observable<any> {
    let url = environment.PDADApiBaseUrl + "/api/DocumentTypes";
    let headers: HttpHeaders;
    headers = new HttpHeaders({ 'cache-response': 'true' });

    return this.httpData.get(url, { headers: headers });
  }

  saveRelatedDocuments(data): Observable<HttpResponse<any>> {
    let url = environment.PDADApiBaseUrl + "/api/CountryRelatedDocuments";
    return this.httpData.post<HttpResponse<any>>(url, data);
  }

  getLatestUploadedDocuments(): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/CountryRelatedDocuments/GetLatestUploadedDocuments';
    return this.httpData.get<any>(url);
  }

  approve_reject_RelatedDocuments(data): Observable<DocumentsInfo[]> {
    let url = environment.PDADApiBaseUrl + "/api/CountryRelatedDocuments";
    return this.httpData.put<DocumentsInfo[]>(url, data);
  }

  getApprovedDocuments(): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/CountryRelatedDocuments/GetApprovedDocuments';
    return this.httpData.get<any>(url);
  }

  updateRelatedDocuments(data): Observable<HttpResponse<any>> {
    let url = environment.PDADApiBaseUrl + "/api/CountryRelatedDocuments/UpdateDocument";
    return this.httpData.post<HttpResponse<any>>(url, data);
  }

  saveRelatedDocumentsByAdmin(data): Observable<HttpResponse<any>> {
    let url = environment.PDADApiBaseUrl + "/api/CountryRelatedDocuments/UploadDocumentByAdmin";
    return this.httpData.post<HttpResponse<any>>(url, data);
  }

  getCountriesByRegionId(regionID): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/Countries/GetCountriesByRegionId'; 
    return this.httpData.get<any>(url + '?RegionID=' + regionID);
  }

  getCountriesByMultipleRegionId(regionIDs): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/Countries/GetCountriesByRegionIds'; 
    return this.httpData.get<any>(url + '?RegionIDs=' + regionIDs);
  }

  getGoodPolicesPractices(countryId): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/GoodPolicyPractices/GetGoodPolicyPracticesByCountryId'; 
    return this.httpData.get<any>(url + '?CountryId=' + countryId);
  }

  
  
  getRelatedTemplates(countryID): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/CountryRelatedTemplate'; 
    return this.httpData.get<any>(url + '?CountryID=' + countryID);
  }

  getCategories(): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/Category';

    let headers: HttpHeaders;
    headers = new HttpHeaders({ 'cache-response': 'true' });
    
    return this.httpData.get(url, { headers: headers });
  }

  getCountryRelatedLinks(countryId): Observable<any> {
    let url = environment.PDADApiBaseUrl + '/api/CountryRelatedLinks'; 
    return this.httpData.get<any>(url + '?CountryId=' + countryId);
  }

  getEvaluationResources(url ,apiCode):Observable<any> {
    return this.httpData.get<any>(url  + apiCode);
  }
}
export class DocumentsInfo {
  countryRelatedDocumentID: number;
  title: string;
  countryName: string;
  countryID: number;
  documentTypeName: string;
  documentPath: string;
  documentLink: string;
  requestedBy: string;
  createdDate: string;
  isSelected: boolean = false;
}

