<div class="wrapper">
    <section class="resources">
        <div class="container">
            <div class="cop-breadcrum">
                <p>
                    <a routerLink="/landing-page">Home <i class="fas fa-chevron-right"></i></a>
                    <a routerLink="/landing-page">What's New</a>
                </p>
            </div>
            <div class="col-lg-12 border-bottom page-header-title">
                What's New
            </div>
            <div class="row">
                <div class="col-lg-12 py-3">
                    <div class="row">
                        <div class="col-lg-5">
                            <ul class="nav nav-pills resources-tablist" id="pills-tab" role="tablist">
                                <li class="nav-item" role="whatsnew">
                                    <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all"
                                        aria-selected="true">All</button>
                                </li>
                                <li class="nav-item" role="whatsnew">
                                    <button class="nav-link" id="pills-events-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-events" type="button" role="tab"
                                        aria-controls="pills-events" aria-selected="false">Events</button>
                                </li>
                                <li class="nav-item" role="whatsnew">
                                    <button class="nav-link" id="pills-workspace-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-workspace" type="button" role="tab"
                                        aria-controls="pills-workspace" aria-selected="false">Comunities of
                                        Practice</button>
                                </li>
                                <li class="nav-item" role="whatsnew">
                                    <button class="nav-link" id="pills-resources-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-resources" type="button" role="tab"
                                        aria-controls="pills-resources" aria-selected="false">Resources</button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-7">
                            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-lg-2">
                                        <label style="margin-top: 0.50rem;">Filter By</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="text" placeholder="From Date" formControlName="from"
                                            onfocus="(this.type='date')" onblur="(this.type='text')"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.from.errors }" />
                                        <div *ngIf="submitted && f.from.errors" class="invalid-feedback">
                                            <div *ngIf="f.from.errors.required">From Date is required</div>
                                            <div *ngIf="f.from.errors.pattern">From Date must be a valid date in the
                                                format
                                                MM-DD-YYYY</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="text" placeholder="To Date" onfocus="(this.type='date')"
                                            onblur="(this.type='text')" formControlName="to" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.to.errors }" />
                                        <div *ngIf="submitted && f.to.errors" class="invalid-feedback">
                                            <div *ngIf="f.to.errors.required">To Date is required</div>
                                            <div *ngIf="f.to.errors.pattern">To Date must be a valid date in the format
                                                MM-DD-YYYY</div>
                                            <div *ngIf="form.touched && form.invalid">To date should be grater then from
                                                date.</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <button class="btn btn-primary">Submit</button>
                                    </div>
                                    <div class="col-lg-1">
                                        <button class="btn btn-secondary" type="reset"
                                            (click)="onReset()">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-all" role="tabpanel"
                            aria-labelledby="pills-all-tab">
                            <div class="my-4" [id]="contentIdAll">
                                <div class="col-lg-12">
                                    <div class="tbl-outer">
                                        <table class="table table-hover">
                                            <thead class="tbl-bdr-rds">
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Title</th>
                                                    <th>Modified By</th>
                                                    <th>Modified Date</th>
                                                    <!--th>URL</th-->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let whatsNewRecord of whatsNewAllRecordsPaginiation;">
                                                    <td>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 6"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-list-alt fa-lg"></i>
                                                        </span>
                                                        <!-- <span *ngIf="whatsNewRecord.whatsNewModuleId == 1" style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-clipboard fa-lg"></i>
                                                          </span> -->
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 3"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-book fa-lg"></i>
                                                        </span>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 7"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-calendar fa-lg"></i>
                                                        </span> {{whatsNewRecord.whatsNewModuleName}}
                                                    </td>
                                                    <td data-toggle="tooltip" [title]="whatsNewRecord.title">
                                                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}}
                                                            target="blank" class="disabled">
                                                        {{ (whatsNewRecord.title.length > 50) ? (whatsNewRecord.title |
                                                            slice:0:47)+'...': (whatsNewRecord.title) }}
                                                        </a>
                                                        <span *ngIf="whatsNewRecord.url==null">
                                                            {{ (whatsNewRecord.title.length > 50) ? (whatsNewRecord.title |
                                                                slice:0:47)+'...': (whatsNewRecord.title) }}
                                                        </span>
                                                    </td>
                                                    <td>{{whatsNewRecord.modifiedBy}}</td>
                                                    <td>{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY h:mm:ss'}}
                                                    </td>
                                                    <!--td></td-->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="whatsNewAllRecordsPaginiation.length">
                                <kendo-datapager [attr.aria-controls]="contentIdAll" [style.width.%]="100"
                                    [pageSize]="pageSizeTabAll" [skip]="skipTabAll" [total]="totalTabAll"
                                    (pageChange)="onWhatsNewAllRecordsPageChange($event)">
                                </kendo-datapager>
                            </ng-container>
                        </div>

                        <div class="tab-pane fade" id="pills-events" role="tabpanel" aria-labelledby="pills-events-tab">
                            <div class="my-4" [id]="contentIdEvents">
                                <div class="col-lg-12">
                                    <div class="tbl-outer">
                                        <table class="table table-hover">
                                            <thead class="tbl-bdr-rds">
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Title</th>
                                                    <th>Event Date</th>
                                                    <th>Modified By</th>
                                                    <th>Modified Date</th>
                                                    <!--th>URL</th-->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let whatsNewRecord of whatsNewEventsRecordsPaginiation;">
                                                    <td>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 6"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-list-alt fa-lg"></i>
                                                        </span>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 3"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-book fa-lg"></i>
                                                        </span>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 7"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-calendar fa-lg"></i>
                                                        </span> {{whatsNewRecord.whatsNewModuleName}}
                                                    </td>
                                                    <td data-toggle="tooltip" [title]="whatsNewRecord.title">
                                                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}}
                                                        target="blank" class="disabled">
                                                    {{ (whatsNewRecord.title.length > 50) ? (whatsNewRecord.title |
                                                        slice:0:47)+'...': (whatsNewRecord.title) }}
                                                    </a>
                                                    <span *ngIf="whatsNewRecord.url==null">
                                                        {{ (whatsNewRecord.title.length > 50) ? (whatsNewRecord.title |
                                                            slice:0:47)+'...': (whatsNewRecord.title) }}
                                                    </span>
                                                    </td>
                                                    <td><span *ngIf="whatsNewRecord.copEventDate !== null">{{whatsNewRecord.copEventDate | date:'dd/MM/YYYY'}}</span></td>
                                                    <td>{{whatsNewRecord.modifiedBy}}</td>
                                                    <td>{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY h:mm:ss'}}
                                                    </td>
                                                    <!--td><a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}}
                                                            target="blank" class="disabled">URL</a></td-->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="whatsNewEventsRecordsPaginiation.length">
                                <kendo-datapager [attr.aria-controls]="contentIdEvents" [style.width.%]="100"
                                    [pageSize]="pageSizeTabEvents" [skip]="skipTabEvents" [total]="totalTabEvents"
                                    (pageChange)="onWhatsNewEventsRecordsPageChange($event)">
                                </kendo-datapager>
                            </ng-container>
                        </div>

                        <div class="tab-pane fade" id="pills-workspace" role="tabpanel"
                            aria-labelledby="pills-workspace-tab">
                            <div class="my-4" [id]="contentIdWorkspace">
                                <div class="col-lg-12">
                                    <div class="tbl-outer">
                                        <table class="table table-hover">
                                            <thead class="tbl-bdr-rds">
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Title</th>
                                                    <th>Modified By</th>
                                                    <th>Modified Date</th>
                                                    <!--th>URL</th-->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let whatsNewRecord of whatsNewWorkspaceRecordsPaginiation;">
                                                    <td>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 6"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-list-alt fa-lg"></i>
                                                        </span>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 3"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-book fa-lg"></i>
                                                        </span>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 7"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-calendar fa-lg"></i>
                                                        </span> {{whatsNewRecord.whatsNewModuleName}}
                                                    </td>
                                                    <td data-toggle="tooltip" [title]="whatsNewRecord.title">
                                                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}}
                                                        target="blank" class="disabled">
                                                    {{ (whatsNewRecord.title.length > 50) ? (whatsNewRecord.title |
                                                        slice:0:47)+'...': (whatsNewRecord.title) }}
                                                    </a>
                                                    <span *ngIf="whatsNewRecord.url==null">
                                                        {{ (whatsNewRecord.title.length > 50) ? (whatsNewRecord.title |
                                                            slice:0:47)+'...': (whatsNewRecord.title) }}
                                                    </span>
                                                    </td>
                                                    <td>{{whatsNewRecord.modifiedBy}}</td>
                                                    <td>{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY h:mm:ss'}}
                                                    </td>
                                                    <!--td><a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}}
                                                            target="blank" class="disabled">URL</a></td-->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="whatsNewWorkspaceRecordsPaginiation.length">
                                <kendo-datapager [attr.aria-controls]="contentIdWorkspace" [style.width.%]="100"
                                    [pageSize]="pageSizeTabWorkspace" [skip]="skipTabWorkspace"
                                    [total]="totalTabWorkspace"
                                    (pageChange)="onWhatsNewWorkspaceRecordsPageChange($event)">
                                </kendo-datapager>
                            </ng-container>
                        </div>

                        <div class="tab-pane fade" id="pills-resources" role="tabpanel"
                            aria-labelledby="pills-resources-tab">
                            <div class="my-4" [id]="contentIdResources">
                                <div class="col-lg-12">
                                    <div class="tbl-outer">
                                        <table class="table table-hover">
                                            <thead class="tbl-bdr-rds">
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Title</th>
                                                    <th>Modified By</th>
                                                    <th>Modified Date</th>
                                                    <!--th>URL</th-->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let whatsNewRecord of whatsNewResourcesRecordsPaginiation;">
                                                    <td>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 6"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-list-alt fa-lg"></i>
                                                        </span>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 3"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-book fa-lg"></i>
                                                        </span>
                                                        <span *ngIf="whatsNewRecord.whatsNewModuleId == 7"
                                                            style="color: #0032a0;">
                                                            <i class="fas fa-regular fa-calendar fa-lg"></i>
                                                        </span> {{whatsNewRecord.whatsNewModuleName}}
                                                    </td>
                                                    <td data-toggle="tooltip" [title]="whatsNewRecord.title">
                                                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}}
                                                        target="blank" class="disabled">
                                                    {{ (whatsNewRecord.title.length > 50) ? (whatsNewRecord.title |
                                                        slice:0:47)+'...': (whatsNewRecord.title) }}
                                                    </a>
                                                    <span *ngIf="whatsNewRecord.url==null">
                                                        {{ (whatsNewRecord.title.length > 50) ? (whatsNewRecord.title |
                                                            slice:0:47)+'...': (whatsNewRecord.title) }}
                                                    </span>
                                                    </td>
                                                    <td>{{whatsNewRecord.modifiedBy}}</td>
                                                    <td>{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY h:mm:ss'}}
                                                    </td>
                                                    <!--td><a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}}
                                                            target="blank" class="disabled">URL</a></td-->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="whatsNewResourcesRecordsPaginiation.length">
                                <kendo-datapager [attr.aria-controls]="contentIdResources" [style.width.%]="100"
                                    [pageSize]="pageSizeTabResources" [skip]="skipTabResources"
                                    [total]="totalTabResources"
                                    (pageChange)="onWhatsNewResourcesRecordsPageChange($event)">
                                </kendo-datapager>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>