import { Component, OnInit } from '@angular/core';
import { WhatsNewService } from '../../services/whats-new/whats-new.service';
import { PageChangeEvent } from "@progress/kendo-angular-pager";
import { FormBuilder, FormGroup, Validators, ValidatorFn, } from '@angular/forms';

@Component({
  selector: 'app-whats-new-page',
  templateUrl: './whats-new-page.component.html',
  styleUrls: ['./whats-new-page.component.css']
})
export class WhatsNewPageComponent implements OnInit {
  form!: FormGroup;
  submitted = false;

  whatsNewAllRecords: any;
  whatsNewAllRecordsPaginiation: any;
  whatsNewAllRecordsFiltered: any;
  whatsNewAllRecordsActual: any;
  pageSizeTabAll = 10;
  skipTabAll = 0;
  totalTabAll = 0;
  contentIdAll = "content-whatsNewAllRecordsList";
  whatsNewWorkspaceRecords: any;
  whatsNewWorkspaceRecordsPaginiation: any;
  whatsNewWorkspaceRecordsFiltered: any;
  whatsNewWorkspaceRecordsActual: any;
  pageSizeTabWorkspace = 10;
  skipTabWorkspace = 0;
  totalTabWorkspace = 0;
  contentIdWorkspace = "content-whatsNewWorkspaceRecordsList";
  //whatsNewGoodPracticesRecords:any
  whatsNewResourcesRecords: any;
  whatsNewResourcesRecordsPaginiation: any;
  whatsNewResourcesRecordsFiltered: any;
  whatsNewResourcesRecordsActual: any;
  pageSizeTabResources = 10;
  skipTabResources = 0;
  totalTabResources = 0;
  contentIdResources = "content-whatsNewResourcesRecordsList";
  whatsNewEventsRecords: any;
  whatsNewEventsRecordsPaginiation: any;
  whatsNewEventsRecordsFiltered: any;
  whatsNewEventsRecordsActual: any;
  pageSizeTabEvents = 10;
  skipTabEvents = 0;
  totalTabEvents = 0;
  contentIdEvents = "content-whatsNewEventsRecordsList";
  strartIndex = 0;
  endIndex = 10;
  loader = true;
  constructor(private whatsnew: WhatsNewService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      from: ['', [
        Validators.required,
        // validates date format yyyy-mm-dd with regular expression
        Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/),
        this.dateRangeValidator
      ]],
      to: ['', [
        Validators.required,
        // validates date format yyyy-mm-dd with regular expression
        Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/),
        this.dateRangeValidator
      ]]
    });
    this.getWhatsNewDetails();
  }

  getWhatsNewDetails() {
    //Workspace, Good Practices, Resources
    this.whatsnew.getWhatsNewDetails().subscribe(data => {
      this.whatsNewAllRecords = data;
      this.whatsNewAllRecordsActual = this.whatsNewAllRecords;
       console.log(this.whatsNewAllRecords);
      // this.whatsNewAllRecords = this.whatsNewAllRecords.slice(this.strartIndex, Math.floor(this.endIndex));
      //console.log("what's new all details", this.whatsNewAllRecords);
      this.whatsNewWorkspaceRecords = data.filter(items => items.whatsNewModuleId == 6);
      this.whatsNewWorkspaceRecordsActual = this.whatsNewWorkspaceRecords;
      //console.log("what's new workspace details", this.whatsNewWorkspaceRecords);
      //this.whatsNewGoodPracticesRecords = data.filter(items => items.whatsNewModuleId == 1).slice(this.strartIndex,Math.floor(this.endIndex));
      //console.log("what's new good practices details", this.whatsNewGoodPracticesRecords);
      this.whatsNewResourcesRecords = data.filter(items => items.whatsNewModuleId == 3);
      this.whatsNewResourcesRecordsActual = this.whatsNewResourcesRecords;
      //console.log("what's new resources details", this.whatsNewResourcesRecords);
      this.whatsNewEventsRecords = data.filter(items => items.whatsNewModuleId == 7);
      this.whatsNewEventsRecordsActual = this.whatsNewEventsRecords;
      //console.log("what's new good practices details", this.whatsNewEventsRecords);
      // //this.loader = false;
      this.loadAllTabData();
    })
  }

  loadAllTabData() {
    this.whatsNewAllRecordsPageData("");
    this.whatsNewWorkspaceRecordsPageData("");
    this.whatsNewResourcesRecordsPageData("");
    this.whatsNewEventsRecordsPageData("");
  }

  private whatsNewAllRecordsPageData(searchText): void {
    var currentAllList = this.whatsNewAllRecords;
    if (searchText != null && searchText != "") {
      currentAllList = currentAllList.filter((n) =>
        n.resourceTitle
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    }
    this.totalTabAll = currentAllList.length;

    this.whatsNewAllRecordsPaginiation = currentAllList.slice(
      this.skipTabAll,
      this.skipTabAll + this.pageSizeTabAll
    );
  }

  public onWhatsNewAllRecordsPageChange(e: PageChangeEvent): void {
    this.skipTabAll = e.skip;
    this.pageSizeTabAll = e.take;
    this.whatsNewAllRecordsPageDataOnPageChanage();
  }

  private whatsNewAllRecordsPageDataOnPageChanage(): void {
    this.totalTabAll = this.whatsNewAllRecords.length;
    this.whatsNewAllRecordsPaginiation = this.whatsNewAllRecords.slice(
      this.skipTabAll,
      this.skipTabAll + this.pageSizeTabAll
    );
  }

  private whatsNewWorkspaceRecordsPageData(searchText): void {
    var currentWorkspaceList = this.whatsNewWorkspaceRecords;
    if (searchText != null && searchText != "") {
      currentWorkspaceList = currentWorkspaceList.filter((n) =>
        n.resourceTitle
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    }
    this.totalTabWorkspace = currentWorkspaceList.length;

    this.whatsNewWorkspaceRecordsPaginiation = currentWorkspaceList.slice(
      this.skipTabWorkspace,
      this.skipTabWorkspace + this.pageSizeTabWorkspace
    );
  }

  public onWhatsNewWorkspaceRecordsPageChange(e: PageChangeEvent): void {
    this.skipTabWorkspace = e.skip;
    this.pageSizeTabWorkspace = e.take;
    this.whatsNewWorkspaceRecordsPageDataOnPageChanage();
  }

  private whatsNewWorkspaceRecordsPageDataOnPageChanage(): void {
    this.totalTabWorkspace = this.whatsNewWorkspaceRecords.length;
    this.whatsNewWorkspaceRecordsPaginiation = this.whatsNewWorkspaceRecords.slice(
      this.skipTabWorkspace,
      this.skipTabWorkspace + this.pageSizeTabWorkspace
    );
  }

  private whatsNewResourcesRecordsPageData(searchText): void {
    var currentResourcesList = this.whatsNewResourcesRecords;
    if (searchText != null && searchText != "") {
      currentResourcesList = currentResourcesList.filter((n) =>
        n.resourceTitle
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    }
    this.totalTabResources = currentResourcesList.length;

    this.whatsNewResourcesRecordsPaginiation = currentResourcesList.slice(
      this.skipTabResources,
      this.skipTabResources + this.pageSizeTabResources
    );
  }

  public onWhatsNewResourcesRecordsPageChange(e: PageChangeEvent): void {
    this.skipTabResources = e.skip;
    this.pageSizeTabResources = e.take;
    this.whatsNewResourcesRecordsPageDataOnPageChanage();
  }

  private whatsNewResourcesRecordsPageDataOnPageChanage(): void {
    this.totalTabResources = this.whatsNewResourcesRecords.length;
    this.whatsNewResourcesRecordsPaginiation = this.whatsNewResourcesRecords.slice(
      this.skipTabResources,
      this.skipTabResources + this.pageSizeTabResources
    );
  }

  private whatsNewEventsRecordsPageData(searchText): void {
    var currentEventsList = this.whatsNewEventsRecords;
    if (searchText != null && searchText != "") {
      currentEventsList = currentEventsList.filter((n) =>
        n.resourceTitle
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    }
    this.totalTabEvents = currentEventsList.length;

    this.whatsNewEventsRecordsPaginiation = currentEventsList.slice(
      this.skipTabEvents,
      this.skipTabEvents + this.pageSizeTabEvents
    );
  }

  public onWhatsNewEventsRecordsPageChange(e: PageChangeEvent): void {
    this.skipTabEvents = e.skip;
    this.pageSizeTabEvents = e.take;
    this.whatsNewEventsRecordsPageDataOnPageChanage();
  }

  private whatsNewEventsRecordsPageDataOnPageChanage(): void {
    this.totalTabEvents = this.whatsNewEventsRecords.length;
    this.whatsNewEventsRecordsPaginiation = this.whatsNewEventsRecords.slice(
      this.skipTabEvents,
      this.skipTabEvents + this.pageSizeTabEvents
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    else {
      this.whatsNewAllRecordsFiltered = this.whatsNewAllRecords.filter(res =>
        new Date(res.modifiedDate) >= new Date(this.form.value.from) 
        && new Date(res.modifiedDate) <= new Date(this.form.value.to));
      this.whatsNewAllRecords = this.whatsNewAllRecordsFiltered;
      
      this.whatsNewWorkspaceRecordsFiltered = this.whatsNewWorkspaceRecords.filter(res =>
        new Date(res.modifiedDate) >= new Date(this.form.value.from) 
        && new Date(res.modifiedDate) <= new Date(this.form.value.to));
      this.whatsNewWorkspaceRecords = this.whatsNewWorkspaceRecordsFiltered;
      
      this.whatsNewResourcesRecordsFiltered = this.whatsNewResourcesRecords.filter(res =>
        new Date(res.modifiedDate) >= new Date(this.form.value.from) 
        && new Date(res.modifiedDate) <= new Date(this.form.value.to));
      this.whatsNewResourcesRecords = this.whatsNewResourcesRecordsFiltered;
      
      this.whatsNewEventsRecordsFiltered = this.whatsNewEventsRecords.filter(res =>
        new Date(res.modifiedDate) >= new Date(this.form.value.from) 
        && new Date(res.modifiedDate) <= new Date(this.form.value.to));
      this.whatsNewEventsRecords = this.whatsNewEventsRecordsFiltered;
      
      this.loadAllTabData();
    }
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
    this.whatsNewAllRecords = this.whatsNewAllRecordsActual;
    this.whatsNewWorkspaceRecords = this.whatsNewWorkspaceRecordsActual;
    this.whatsNewResourcesRecords = this.whatsNewResourcesRecordsActual;
    this.whatsNewEventsRecords = this.whatsNewEventsRecordsActual;
    this.loadAllTabData();
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.form! && this.form!.get("from").value;
    const to = this.form! && this.form!.get("to").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
}
