<div class="wrapper">
    <section class="policy-hub mb-5">
        <div class="banner-slider-sec" [attr.id]="'landing-page'">

            <div>
                <div class="col-lg-12 col-md-12 text-on-slide">
                    <div>
                        <div class="container">
                            <img class="imgOnSlide" src="../assets/images/Footer/poem.png" alt="no img">
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <app-brief-info></app-brief-info>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 slider">
                    <app-slide-show></app-slide-show>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="mb-5">
        <div class="policy-hub-display-sec">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <a href="{{landingPageKnowledgePlatforms}}" target="_blank"><img src="../../assets/images/Landing-Page/knowledge-platform.png" style="margin-top: 2%;"/></a>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <a href="{{landingPageInnovativeInitiative}}" target="_self"><img src="../../assets/images/Landing-Page/innovative-initiatives.png" style="height: 99%;"/></a>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <a href="{{landingPageGoodPractices}}" target="_self"><img src="../../assets/images/Landing-Page/Good-Practice.png" style="margin-top: 0.8%;"/></a>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- <section class="mb-5">
        <div class="policy-hub-about-sec">
            <div class="container p-0">
                <div class="row">
                    <div class="col-lg-6 col-md-12 about">
                        <div class="row"
                            style="text-align: center;padding:0px; cursor: pointer;"
                            (click)="onKMGuideNavigation()">
                            <img src="../../assets/images/KMGuide/KM Guide.png" alt=""
                                style="width: 100%;z-index: -100;position: relative">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 about other-screen-margin">
                        <div class="row"
                            style="text-align: center;padding:0px;cursor: pointer;"
                            (click)="onIASiteNavigation()">
                            <img src="../../assets/images/IA2023/Banner IA Submission.jpg" alt=""
                                style="width: 100%;z-index: -100;position: relative;margin-bottom: 4%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <app-whats-new></app-whats-new>
    <app-featured-good-practices></app-featured-good-practices>
    <section class="py-5 news_highlights">
        <div style="padding: 20px;">
            <!--<h3 class="text-center">News Highlights</h3>-->
            <h3 class="text-center">IOM Strategic Plan Objectives</h3>
            <p-carousel [value]="news_highlights" [numVisible]="3" [circular]="true" [numScroll]="1"
                [showIndicators]=false styleClass="items-carousel" [responsiveOptions]="responsiveOptions" [showNavigators]="false">
                <ng-template let-highlight pTemplate="item">
                    <div class="highlights-carousel_container slick-active slick-center">
                        <div class="highlights-carousel_inner-container">
                            <div (click)="onClickReadMoreURL(highlight.readmoreURL, 'blank')">
                                <img src="{{ highlight.imageURL == '' ? '../../assets/images/Logo_POEM-Blue.svg' : highlight.imageURL }}" alt="no img"
                                class="w-100 highlights-carousel_banner-img">
                                <div>
                                    <p [style.color]="highlight.subtitlecolor" style="margin-top: 2%;">{{highlight.subtitle}}</p>
                                    <h6 class="obj-items" style="font-weight: 600; color: #0033A0; margin: 4% 0%;">
                                        {{highlight.title}}
                                    </h6>
                                    <!--p data-toggle="tooltip" [title]="highlight.description" style="text-align: justify;">
                                        {{ highlight.description }}                                  
                                    </p-->                                
                                </div>
                            </div>
                            <button (click)="onClickReadMoreURL(highlight.url, '_self')"  class="readMoreURL">View Good Practices</button>
                            
                        </div>
                        
                    </div>
                </ng-template>
            </p-carousel>
            <!--<div class="text-center">
                <a href="{{landingNewsHighlights}}" target="_blank"><button class="view-all_btn">View all</button></a>
            </div>-->
        </div>
    </section>
    <!-- <app-events></app-events> -->
    <!-- <div class="migrant-policy-sec mt-4 mb-5">
            <div class="container p-0">
                <div class="row pt-3">
                    <div class="col-lg-8 col-md-12 p-2">
                        <app-migration-policy-repository></app-migration-policy-repository>
                    </div>
                    <div class="col-lg-4 col-md-12 p-2" (click)="onDGFilesNavigation()">
                        <div class="director-gen d-flex flex-column justify-content-between">
                            <p>
                                DG's Files
                            </p>
                            <p class="mb-0">
                                António Vitorino
                                <span class="d-block">Director General</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- <section class="virtual-workspace my-5">
        <div class="container virtual-bg"  [attr.id]="'virtual-workspace'"> -->

    <!-- <section class="pdad my-5"> 
        <div class="container pdad-bg" [attr.id]="'policy-development'"> -->
    <section class="home_lesson-learned mb-5">
        <div class="mb-5" [attr.id]="'policy-development'">
            <app-policy-development-analysis-data></app-policy-development-analysis-data>
        </div>
    </section>
    <!-- <section class="knowledge-sharing my-5">
        <div class="container knowledge-bg" [attr.id]="'knowledge-sharing'"> -->
    <section class="home_knowledge-sharing mb-5">
        <div class="mb-5" [attr.id]="'knowledge-sharing'">
            <app-knowledge-sharing></app-knowledge-sharing>
        </div>
    </section>

    <section class="home_virtual-workspace mb-5" [attr.id]="'virtual-workspace'">
        <div class="mb-5">
            <app-virtual-workspace></app-virtual-workspace>
        </div>
    </section>
    <!-- <section class="feedback my-5">
        <div class="container p-0" [attr.id]="'feedback'">
            <div class="feed-wrapper pb-4">
                <app-feedback></app-feedback>           
            </div>
        </div>
    </section>  -->
    
</div>