<div class="wrapper">
    <section class="policy-hub" id="poly">
        <div class="banner-slider-sec">
            <div class="container ps-0">
                <h3 class="title">{{message}}</h3>
            </div>
            <div class="learn-more-img">
                <div class="container">
                    <div class="row col-lg-6">
                        <!-- <img src="{{messageImage}}" alt=""> -->
                        <img src="../assets/images/EnableCookies.png" alt="">
                    </div>
                </div>
            </div>
        </div>

    </section>
    <br>
</div>