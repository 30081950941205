<!--<form #feedbackForm="ngForm" (ngSubmit)="feedbackForm.form.valid && onSubmit(feedbackForm)" novalidate>
    
<div class="row">
    <div class="col-lg-9 col-md-12">     
        <textarea class="form-control feed-input" (keypress)="omit_space_char_atstart($event)" 
        placeholder="How are we doing?" maxlength="300" [(ngModel)]="txtFeedback" id="feedbackdata" name="feedbackdata" required #feedbackdata="ngModel"></textarea>
           
        <div class="mb-0 char-left"> 
            <p class="alignleft">Please tell us about your experience. The more details you share, the better.</p>
            <p class="text-end alignright">{{300-txtFeedback.length}} / 300 </p>
            <div style="clear: both;"></div>
        </div>        
        <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="submitted" [hidden]="feedbackForm.valid">
            Feedback Submitted.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
    <div class="col-lg-3 col-md-5">
        <button class="btn feed-btn" type="submit" [disabled]="feedbackForm.invalid">
            submit feedback
            <span><i class="fas fa-chevron-right"></i></span>
        </button>
    </div>

</div>
</form>-->

<form>
  <div class="row">
    <div class="col-lg-9 col-md-12">
    </div>
    <div class="col-lg-3 col-md-5">
      <button class="btn feed-btn" (click)="onFeedBackClick($event)">
        feedback
        <span><i class="fas fa-chevron-right"></i></span>
      </button>
    </div>
  </div>
</form>
