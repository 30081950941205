  <div class="wrapper">
    <section class="policy-hub" id="poly" style="display: none;">
      <div class="banner-slider-sec">
        <div class="container ps-0">
          <h1 class="title">{{message}}</h1>
        </div>
        <div class="learn-more-img">
          <div class="container">
            <div class="learn-more-img-wrapper">
              <img src="{{messageImage}}" alt="">
            </div>
          </div>
        </div>
      </div>


      <div class="policy-hub-about-sec learn-more-detail">

        <div class="container" [innerHTML]="messageDesc"></div>

        <!--<div class="container">
          <p>
            The Policy Hub was established in April 2019 as a unit within the Office of the Director General,
            in response to growing calls from the Member States for IOM to improve its policy development
            and coordination capacity and support the implementation of the Strategic Vision. The objective
            of the Policy Hub is to work across the Organization and all its thematic areas to facilitate
            strategic policy coordination and to foster migration policy knowledge, learning and sharing.
            Conceived as an innovative initiative, the Policy Hub’s format and key functions were defined
            taking into account the need for flexibility in an evolving institutional setting and with an
            understanding that regular review and adjustment may be needed to ensure its continued relevance
            and effectiveness.
          </p>
          <p>
            At its inception, the Policy Hub was tasked with spearheading two interrelated pillars of work of
            direct relevance to the improvement of IOM’s policy capacity: migration data and policy-related
            knowledge management. The Policy Hub has also assisted with numerous policy-relevant ad hoc
            requests for support to cross-cutting areas of policy work.
          </p>
          <p>
            Broadly, the Policy Hub’s work can be classified under one of two categories: support to Policy
            Development and Coordination; and policy-relevant knowledge management, sharing and learning.
            Notable examples from each category are listed below:
          </p>
          <p>Support to policy development and coordination </p>
          <p>
            Facilitation of the development of the IOM Migration Data Strategy (MDS) in line with the
            Strategic Vision, and support to several data task forces under the leadership of different
            members of the Data Steering Group to enhance quality and consistency of IOM migration data
            throughout its lifecycle;
          </p>

          <ul class="p-0">
            <li>
              Coordination of a whole-of-organization process to
              develop a new policy on IOM’s approach to the full spectrum of return, readmission and
              reintegration.
            </li>
            <li>
              Leading cross-departmental discussions around IOM’s
              engagement with the private sector on labor supply chain integrity issues through an
              informal working group, to identify the Organization’s needs regarding knowledge management
              and policy development in this increasingly important area of work. Policy-relevant
              knowledge management sharing and learning
            </li>
            <li>
              Development, launch, and curation of the IOM Migration
              Policy Repository as a tool for sharing and learning from IOM’s policy-related initiatives
              around the globe;
            </li>
            <li>
              Ongoing development of a policy hub platform as a
              collaborative space for knowledge sharing and learning on migration policy development
              comprising interactive sections such as community of practices, peer to peer learning
              spaces, migration policy initiatives at country level, good practices and lesson learned;
            </li>
            <li>
              Development and ongoing delivery of training for IOM
              staff across the Organization on the three-key migration- related global policy frameworks
              –the 2030 Agenda, the GCM, the MiGOF -and IOM’s Strategic Vision –and their interlinkages;
            </li>
          </ul>


          <p>
            Support to knowledge management efforts of discrete and emerging migration policy areas and
            processes and innovation, including alternatives to detention (ATD), rights-based approaches to
            programming, and analysis of the social and economic impacts of COVID19 to ensure its
            integration in response measures by IOM, governments and the international community at large.
          </p>





        </div>-->
      </div>

    </section>


    <section class="policy-hub" id="poly">
      <div class="banner-slider-sec">
        <div class="container ps-0">
          <h1 class="title">{{messageMission}}</h1>
        </div>
        <div class="learn-more-img">
          <div class="container">
            <div class="learn-more-img-wrapper">
              <img src="{{messageImageMission}}" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="policy-hub-about-sec learn-more-detail">
        <div class="container" [innerHTML]="messageDescMission"></div>
      </div>
    </section>

    <div style="margin-bottom: 3%;"><hr style="width: 60%;margin: auto;color: #0033A0;"/></div>

    <section class="policy-hub" id="poly">
      <div class="banner-slider-sec">
        <div class="container ps-0">
          <h1 class="title">{{messageBriefIntro}}</h1>
        </div>
        <div class="learn-more-img">
          <div class="container">
            <div class="learn-more-img-wrapper">
              <img src="{{messageImageBriefIntro}}" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="policy-hub-about-sec learn-more-detail">
        <div class="container" [innerHTML]="messageDescBriefIntro"></div>
      </div>
    </section>

  </div>
