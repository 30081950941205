import { Injectable } from "@angular/core";
import * as Msal from "msal";
import { environment } from "../../../environments/environment";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-common";
import { Roles } from "../../../../src/app/constants/constants";
import { GraphUserService } from "../../../app/common/services/graph-user.service";

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[];
  };
}

@Injectable({
  providedIn: "root",
})
export class MsalUserService {
  private accessToken: any;
  public clientApplication: Msal.UserAgentApplication = null;
  private graphUser: any;
  private graphUser$: Observable<any>;
  private currentUserSubject: BehaviorSubject<any>;
  private subject = new Subject<string>();
  constructor(
    private authService: MsalService,
    private graphUserService: GraphUserService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(null);
    this.graphUser$ = this.currentUserSubject.asObservable();

    //this.setGraphUser();
  }

  public GetAccessToken(): Observable<any> {
    const requestObj = {
      scopes: environment.scopeUri,
    };

    if (
      sessionStorage.getItem("msal.idtoken") !== undefined &&
      sessionStorage.getItem("msal.idtoken") != null
    ) {
    }
    return this.accessToken;
  }
  public authCallback(errorDesc, token, error, tokenType) {
    if (token) {
    } else {
      console.log(error + ":" + errorDesc);
    }
  }

  public getUserRoles() {
    let account: Account = this.authService.instance.getActiveAccount();
    if (account.idTokenClaims.roles !== null) {
      return account.idTokenClaims?.roles ?? Roles.IOMUser;
    } else {
      return Roles.IOMUser;
    }
    //return account.idTokenClaims?.roles ?? Roles.IOMUser;
  }

  public getCurrentUserName() {
    let account: Account = this.authService.instance.getActiveAccount();
    return account.username;
  }

  IsLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  public setGraphUser() {
    this.graphUserService.getGraphUser().subscribe((profile) => {
      this.graphUser = profile;
      this.sendLoginedUserEmailID(this.graphUser.mail);
      this.currentUserSubject.next(profile);
    });
  }
  public getGraphUser() {
    return this.graphUser;
  }
  public getGraphUserObservable() {
    return this.graphUser$;
  }
  public logout() {
    this.clientApplication.logout();
  }

  public isCurrentUserAdmin() {
    let account: Account = this.authService.instance.getActiveAccount();
    return account.idTokenClaims?.roles.indexOf(Roles.Admin);
  }
  public get userEmailId() {
    return this.graphUser.mail;
  }

  sendLoginedUserEmailID(emailId: string) {
    this.subject.next(emailId);
  }

  receivedLoginedUserEmailID(): Observable<string> {
    return this.subject.asObservable();
  }
}
