<section class="py-5 mb-5 featured-good-practices">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
          <div class="row m-0">
            <div class="col-lg-6">
              <h3>Latest Good Practices</h3>
            </div>
          </div>
          <div class="carousel-inner" *ngIf="gpRecords.length > 0">
            <!-- For autoplay - automatic scroll use below property. -->
            <!-- autoplayInterval="3000" -->
            <p-carousel [value]="gpRecords" [numVisible]="2" [circular]="true" [numScroll]="2" [showIndicators]=true
              styleClass="items-carousel" [responsiveOptions]="responsiveOptions">
              
              <!-- <ng-template ngFor let-gpRecord [ngForOf]="gpRecords" pTemplate="item"> -->
              <ng-template let-gpAllRecord pTemplate="item">
                <h4 *ngFor="let gpRecord of gpAllRecord.itemList;">
                 
                   <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-12" style="display: inline-grid;">
                        <a href="{{gpRecord.documentLink}}" target="_blank" class="anch_item">
                        <div class="col-lg-12 d-flex align-items-center featured-good-practices_row">
                          <div class="col-lg-3 featured-good-practices_imgBan">
                              <h4 class="imgBan">
                                <img src="{{gpRecord.documentImageLink + appendToUrl}}"/>
                               </h4>
                          </div>
                          <div class="col-lg-9 gp-details-container">
                            <h6 class="text-truncate" data-toggle="tooltip" [title]="gpRecord.documentTitle" style="font-weight:600;font-size:15px">
                             <!-- {{ gpRecord.countryName  }}  -  {{ (gpRecord.documentTitle.length > 70) ? (gpRecord.documentTitle | slice:0:63)+'...': -->
                             {{ gpRecord.countryName.length > 0? gpRecord.countryName : gpRecord.regionName  }}  -   {{ ( ((gpRecord.countryName.length > 0 ? gpRecord.countryName.length : gpRecord.regionName.length ) + gpRecord.documentTitle.length) > 70) ? (gpRecord.documentTitle | slice:0:43)+'...': gpRecord.documentTitle }}
                            </h6>
                            <p class="text-truncate" 
                              data-toggle="tooltip" [title]="gpRecord.description">
                              {{ (gpRecord.description?.length > 80) ? (gpRecord.description |
                              slice:0:77)+'...': (gpRecord.description) }}
                            </p>
                          </div>
                       
                        </div>
                      </a>
                      </div>
                    </div>
                  </div>
              
                </h4> 
                
              </ng-template>
            </p-carousel>
          </div>
          <div *ngIf="gpRecords.length == 0">
            There are no new upcoming gps.
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal mt-auto" id="gplandModal" [style.display]="showModal ? 'block' : 'none'" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mnw-700">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #ECF6FF;">
          <h5 class="modal-title" id="uploadModalLabel">Events</h5>
          <button type="button" class="btn-close" style="background-color:  #ECF6FF;" data-dismiss="modal"
            (click)="hide()" aria-label="Close"></button>
        </div>
        <div class="modal-body p-30" style="background-color:  #FFFFFF;">
          <div class="row gx-5">
            <div class="col-md-2 pe-3">
              <div class="news-icon pt-2">
                <p class="mb-0 mx-auto" style="font-size:31px;">
                  {{gpDate | date:'dd'}}
                  <span class="month">{{gpDate | date:'MMM'}}</span>
                </p>
              </div>
            </div>
            <div class="col-md-10 ps-0">
              <div class="news-content p-2">
                <p class="news-title mb-0">{{gpsTitle}}</p>
                <p class="news-writup mb-0" data-toggle="tooltip" [title]="gpDescription">
                  {{gpDescription}}
                </p>
              </div>
              <button (click)="gotoEvent(gpLink)" class="btn btn-new btn-bg-white gp-pop-btn mt-3"><i
                  class="fas fa-link me-2"></i>Click
                here to join
                gp</button>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="background-color:  #FFFFFF;">
        </div>
      </div>
    </div>
  </div> -->
</section>