import { HttpClient } from '@angular/common/http';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingSectionService } from '../../services/landing-section/landing-section.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.css']
})
export class LearnMoreComponent implements OnInit {

  message;
  messageDesc;
  messageImage:any;
  paramKey;

  messageMission;
  messageDescMission;
  messageImageMission:any;

  messageBriefIntro;
  messageDescBriefIntro;
  messageImageBriefIntro:any;

  

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private landingSectionService: LandingSectionService) {
    this.paramKey = this.route.snapshot.params['paramKey'];  
  }

  ngOnInit(): void {
    /*
    var desc = sessionStorage.getItem("briefIntroDesc");

    if (this.paramKey == "missionDesc") {
      this.message = "About Us";
      this.messageDesc = localStorage.getItem("missionDesc");;
      this.messageImage = localStorage.getItem("missionImagePath");
    }
    else if (this.paramKey == "briefIntrouctionDesc") {
      this.message = "About Innovation and Knowledge Management Unit";//data[0].briefIntrouction;
      this.messageDesc = localStorage.getItem("briefIntroDesc");;
      this.messageImage = localStorage.getItem("briefIntroImagePath");
    }
    */

    this.getSetInfo();
  }

  getSetInfo(){

      this.landingSectionService.getLandingSectionDetails().subscribe(data =>
      {
        if (data[0]?.briefIntrouction != null) {        
          //Brief Introduction
          this.messageBriefIntro = "About Innovation and Knowledge Management Unit";
        
          let msgBriefIntro = String(data[0].briefIntrouctionDesc).replace("ikm@iom.int", "<a href = 'mailto: ikm@iom.int'>ikm@iom.int</a>");
          this.messageDescBriefIntro = msgBriefIntro;

          let imgBriefIntro = String(data[0].briefIntrouctionImagePath) + environment.azblobSecurityUrl;
          this.messageImageBriefIntro = imgBriefIntro;  
          
        }

        if (data[0]?.mission != null) {
          //About Us
          this.messageMission = "About Us";
    
          let msgMission = String(data[0].missionDesc).replace("ikm@iom.int", "<a href = 'mailto: ikm@iom.int'>ikm@iom.int</a>");        
          this.messageDescMission = msgMission;
    
          let imgMission = String(data[0].missionImagePath) + environment.azblobSecurityUrl;
          this.messageImageMission = imgMission;
        }
      });
  }
}
