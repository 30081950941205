import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewshighlightsService {

  constructor(private httpData: HttpClient) { }

  getNewshighlightsData():Observable<any> {
    let url = environment.apiBaseUrl + "/api/newshighlights";console.log(url);
    return this.httpData.get(url);
  }
}
