import { Component, OnInit } from '@angular/core';
import { microsoftURL } from '../../../environments/environment';
import { GraphUserService } from '../../../app/common/services/graph-user.service';
import { switchMap } from 'rxjs/operators';
import { ImageConversion } from '../../common/utils/image-conversion';
import { MsalUserService } from '../../common/services/msal-user.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profile$;
  profilePicture: any = '../assets/images/no-profile-picture.png';
  profileLink =  microsoftURL.profileLink;
  
  constructor(private authService :MsalUserService, private graphUserService: GraphUserService) { }

  ngOnInit() {
    this.getProfile();
    this.getProfilePicture();
  }
  getProfile() {
    this.profile$  = this.authService.getGraphUserObservable();
  }

  getProfilePicture() {
    this.graphUserService.getProfilePicture()
      .pipe(
        switchMap(blob => ImageConversion.convertBlobToBase64(blob))
      )
      .subscribe(base64ImageUrl => this.profilePicture = base64ImageUrl);
  }

  redirectoPage(pageUrl){
    debugger;
    window.open(
      pageUrl,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
}
