import { Component, OnInit } from '@angular/core';
import { KnowledgeSharingSection } from '../../../app/constants/constants';

@Component({
  selector: 'app-knowledge-sharing',
  templateUrl: './knowledge-sharing.component.html',
  styleUrls: ['./knowledge-sharing.component.css']
})
export class KnowledgeSharingComponent implements OnInit {
  sectionConstant: any;

  constructor() {
    this.sectionConstant = KnowledgeSharingSection; 
   }

  ngOnInit(): void {
  }

}
