import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceListService {
  constructor(private httpData : HttpClient) { }  
  workspacelistUrl = environment.apiBaseUrl + "/api/WorkspaceList";

  getWorkspaceList() : Observable<any>{
    return this.httpData.get(this.workspacelistUrl);
  }

  getWorkspaceListByTeamId(emailId) : Observable<any>{
    let url = this.workspacelistUrl + "/GetWorkspaceListByTeamId?emailId=" + emailId;
    return this.httpData.get(url);
  }

  getWorkspaceListByName(searchtext) : Observable<any>{
    let url = this.workspacelistUrl + "/GetWorkspaceListByName?WorkspaceName=" + searchtext;
    return this.httpData.get(url);
  }

  getAdminWorkspaceList() : Observable<any>{
    let url = this.workspacelistUrl + "/GetAdminWorkspaceList";
    return this.httpData.get(url);
  }

  getWorkspaceMembers(teamWorkspaceId) : Observable<any>{
    let url = this.workspacelistUrl + "/GetWorkspaceMembers?teamWorkspaceId=" + teamWorkspaceId;
    return this.httpData.get(url);
  }

  SearchUserByName(name) : Observable<any>{
    let url = this.workspacelistUrl + "/SearchUserByName?name=" + name;
    return this.httpData.get(url);
  }

  SaveWorkspace(workspace): Observable<HttpResponse<boolean>> {
    let url = this.workspacelistUrl + "/UpdateWorkspaceTeam";
    return this.httpData.put<HttpResponse<boolean>>(url, workspace);
  }

}
