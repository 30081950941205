import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackDetailsService {  

  constructor(private httpData : HttpClient) { }

  getEventsData(id) : Observable<any>{
    let url = environment.apiBaseUrl + "/api/ChatbotFeedbackDetails";
    return this.httpData.get(url);
  }

  saveFeedbackDetails(data):Observable<any>{
    let url = environment.apiBaseUrl + "/api/ChatbotFeedbackDetails";
    return this.httpData.post(url,data);
  }

  updateEventsData(data):Observable<any>{
    let url = environment.apiBaseUrl + "/api/ChatbotFeedbackDetails";
    return this.httpData.put(url,data);
  }

  
}
