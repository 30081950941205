import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlideShowService {

  constructor(private httpClient : HttpClient) { }

  getSlideShowImages() :Observable<any>
  {
    let url = environment.apiBaseUrl + "/api/slideshow";
    return this.httpClient.get(url);
  }

  SaveSlideShowImage(file): Observable<any> {
    const formData = new FormData();
    if (file != null) {
      if (file.length !== 0) {
        formData.append("file", JSON.stringify(file));
      }
    }

    let url = environment.apiBaseUrl + "/api/SlideShow/SaveSlideShowImages";
    const uploadReq = new HttpRequest('POST', url, formData);
    return this.httpClient.request(uploadReq);
  }
}
