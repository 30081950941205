import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { microsoftURL } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraphUserService {
  private graphUser: any;

  constructor(private http: HttpClient) { }

  getGraphUser() : Observable<any>{
    return this.http.get(microsoftURL.graphEndpoint);
  }

  getProfilePicture() {
   return this.http.get(microsoftURL.graphEndpoint + '/photo/$value', { responseType: 'blob' });
  }

}