import { Component, OnInit } from '@angular/core';
import { LandingSectionService } from '../../../app/services/landing-section/landing-section.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  aboutUsInfo: any;
  constructor(private landingSectionService: LandingSectionService) {
  }

  ngOnInit(): void {
    this.getAboutUSInfo();
  }

  getAboutUSInfo() : any
  {
     this.landingSectionService.getLandingSectionDetails().subscribe(data =>
      {
       if (data[0]?.mission != null) {
         localStorage.setItem("missionDesc", data[0].missionDesc);
         localStorage.setItem("missionImagePath", data[0].missionImagePath);
         this.aboutUsInfo = data[0].mission;
       }
     })
  }

}
