import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WhatsNewService {

  constructor(private httpData : HttpClient) { }

  getWhatsNewData() {
    let url = environment.apiBaseUrl + "/api/whatsnew";
    return this.httpData.get(url);
  }

  getMPRData(countryCode){
    let url = environment.PDADApiBaseUrl + "/api/MPRCountry?countryCode="+countryCode;
    return this.httpData.get<any>(url);
  }

  getWhatsNewDetails():Observable<any>{
    let url = environment.apiBaseUrl + "/api/whatsnew/WhatsNewDetails";
    return this.httpData.get(url);
  }

  getMPRSPODataByRegionCode(regionCode){
    let url = environment.PDADApiBaseUrl + "/api/MPRSPO/GetDataByRegion?regionCode="+regionCode;
    return this.httpData.get<any>(url);
  }

  getMPRSPODataByCountryCode(countryCode){
    let url = environment.PDADApiBaseUrl + "/api/MPRSPO/GetDataByCountry?countryCode="+countryCode;
    return this.httpData.get<any>(url);
  }
}
