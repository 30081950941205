<section class="policy-migration mb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="updates-inner_section">
          <div class="d-flex justify-content-between whats-new">
            <h4 class="d-flex align-items-center">What’s New</h4>
            <!-- <span class="count">{{whatsNewAllRecords.length}}</span> -->
            <a routerLink="/whats-new-page"><button>View all <img
                  src="../../../assets/images/right-button-arrow-white.svg" alt="no img" class="ms-1">
              </button></a>
          </div>
          <div class="d-flex" *ngIf="whatsNewAllRecords ==null || whatsNewAllRecords.length == 0 && loader== true">
            <div>
              <div class="reverse-spinner"></div>
            </div>
            <div>
              <span>Checking for data, please wait...</span>
            </div>
          </div>

          <div *ngIf="whatsNewAllRecords !=null || whatsNewAllRecords.length != 0 && loader== false">
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="updates">
                <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all"
                  type="button" role="tab" aria-controls="pills-all" aria-selected="true">All</button>
              </li>
              <li class="nav-item" role="updates">
                <button class="nav-link" id="pills-events-tab" data-bs-toggle="pill" data-bs-target="#pills-events"
                  type="button" role="tab" aria-controls="pills-events" aria-selected="false">Events</button>
              </li>
              <li class="nav-item" role="updates">
                <button class="nav-link" id="pills-workspace-tab" data-bs-toggle="pill"
                  data-bs-target="#pills-workspace" type="button" role="tab" aria-controls="pills-workspace"
                  aria-selected="false">Communities of Practice</button>
              </li>
              <!-- <li class="nav-item" role="updates">
                <button class="nav-link" id="pills-practices-tab" data-bs-toggle="pill"
                  data-bs-target="#pills-practices" type="button" role="tab" aria-controls="pills-practices"
                  aria-selected="false">Good Practices</button>
              </li> -->
              <li class="nav-item" role="updates">
                <button class="nav-link" id="pills-resources-tab" data-bs-toggle="pill"
                  data-bs-target="#pills-resources" type="button" role="tab" aria-controls="pills-resources"
                  aria-selected="false">Resources</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                <div>
                  <div class="updates-row" *ngFor="let whatsNewRecord of whatsNewAllRecords;">
                    <div class="menu-board_container">
                      <span *ngIf="whatsNewRecord.whatsNewModuleId == 6" style="color: #0032a0;">
                        <i class="fas fa-regular fa-list-alt fa-lg"></i>
                      </span>
                      <!-- <span *ngIf="whatsNewRecord.whatsNewModuleId == 1" style="color: #0032a0;">
                        <i class="fas fa-regular fa-clipboard fa-lg"></i>
                      </span> -->
                      <span *ngIf="whatsNewRecord.whatsNewModuleId == 3" style="color: #0032a0;">
                        <i class="fas fa-regular fa-book fa-lg"></i>
                      </span>
                      <span *ngIf="whatsNewRecord.whatsNewModuleId == 7" style="color: #0032a0;">
                        <i class="fas fa-regular fa-calendar fa-lg"></i>
                      </span>
                    </div>
                    <div class="updates-detail_container">
                      <!--p class="mb-0 date">{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY'}}</p-->
                      <p class="mb-0 details" data-toggle="tooltip" [title]="whatsNewRecord.title">
                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}} target="blank"
                          class="disabled">{{whatsNewRecord.title}}
                          <br/>
                          <span *ngIf="whatsNewRecord.copEventDate !== null && whatsNewRecord.whatsNewModuleId == 7">Event Date : {{whatsNewRecord.copEventDate | date:'dd/MM/YYYY'}}</span>
                        </a>
                      </p>

                    </div>
                    <div class="more-board_container">
                      <a href="#">
                        <img src="../../../assets/images/arrow-right-grey.svg" alt="no img">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-events" role="tabpanel" aria-labelledby="pills-events-tab">
                <div>
                  <div class="updates-row" *ngFor="let whatsNewRecord of whatsNewEventsRecords;">
                    <div class="menu-board_container">
                      <span style="color: #0032a0;">
                        <i class="fas fa-regular fa-calendar fa-lg"></i>
                      </span>
                    </div>
                    <div class="updates-detail_container">
                      <!--p class="mb-0 date">{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY'}}</p-->
                      <p class="mb-0 details" data-toggle="tooltip" [title]="whatsNewRecord.title">
                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}} target="blank"
                          class="disabled">{{whatsNewRecord.title}}
                        <br/>
                        <span *ngIf="whatsNewRecord.copEventDate !== null">Event Date : {{whatsNewRecord.copEventDate | date:'dd/MM/YYYY'}}</span>
                        </a>
                      </p>
                    
                    </div>
                    <div class="more-board_container">
                      <a href="#">
                        <img src="../../../assets/images/arrow-right-grey.svg" alt="no img">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-workspace" role="tabpanel" aria-labelledby="pills-workspace-tab">
                <div>
                  <div class="updates-row" *ngFor="let whatsNewRecord of whatsNewWorkspaceRecords;">
                    <div class="menu-board_container">
                      <span style="color: #0032a0;">
                        <!-- <img src="../../../assets/images/alt-primary.svg" alt="no img"> -->
                        <i class="fas fa-regular fa-list-alt fa-lg"></i>
                      </span>
                    </div>
                    <div class="updates-detail_container">
                      <!--p class="mb-0 date">{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY'}}</p-->
                      <p class="mb-0 details" data-toggle="tooltip" [title]="whatsNewRecord.title">
                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}} target="blank"
                          class="disabled">{{whatsNewRecord.title}}</a>
                      </p>

                    </div>
                    <div class="more-board_container">
                      <a href="#">
                        <img src="../../../assets/images/arrow-right-grey.svg" alt="no img">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="tab-pane fade" id="pills-practices" role="tabpanel" aria-labelledby="pills-practices-tab">
                <div>
                  <div class="updates-row" *ngFor="let whatsNewRecord of whatsNewGoodPracticesRecords;">
                    <div class="menu-board_container">
                      <span style="color: #0032a0;">
                        <i class="fas fa-regular fa-clipboard fa-lg"></i>
                      </span>
                    </div>
                    <div class="updates-detail_container">
                      <p class="mb-0 date">{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY'}}</p>
                      <p class="mb-0 details" data-toggle="tooltip" [title]="whatsNewRecord.title">
                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}} target="blank"
                          class="disabled">{{whatsNewRecord.title}}</a>
                      </p>

                    </div>
                    <div class="more-board_container">
                      <a href="#">
                        <img src="../../../assets/images/arrow-right-grey.svg" alt="no img">
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="tab-pane fade" id="pills-resources" role="tabpanel" aria-labelledby="pills-resources-tab">
                <div>
                  <div class="updates-row" *ngFor="let whatsNewRecord of whatsNewResourcesRecords;">
                    <div class="menu-board_container">
                      <span style="color: #0032a0;">
                        <!-- <img src="../../../assets/images/alt-primary.svg" alt="no img"> -->
                        <i class="fas fa-regular fa-book fa-lg"></i>
                      </span>
                    </div>
                    <div class="updates-detail_container">
                      <!--p class="mb-0 date">{{whatsNewRecord.modifiedDate | date:'dd/MM/YYYY'}}</p-->
                      <p class="mb-0 details" data-toggle="tooltip" [title]="whatsNewRecord.title">
                        <a *ngIf="whatsNewRecord.url!=null" href={{whatsNewRecord.url}} target="blank"
                          class="disabled">{{whatsNewRecord.title}}</a>
                      </p>
                    </div>
                    <div class="more-board_container">
                      <a href="#">
                        <img src="../../../assets/images/arrow-right-grey.svg" alt="no img">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="other-screen-margin">
          <app-migration-policy-repository></app-migration-policy-repository>
        </div>
      </div>
    </div>
  </div>
</section>