import { Component, OnInit } from '@angular/core';
import { WhatsNewService } from '../../services/whats-new/whats-new.service';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.css']
})
export class WhatsNewComponent implements OnInit {
  //whatsNewRecords:any = [];
  whatsNewAllRecords:any;
  whatsNewWorkspaceRecords:any;
  //whatsNewGoodPracticesRecords:any
  whatsNewResourcesRecords:any;
  whatsNewEventsRecords:any;
  strartIndex = 0;
  endIndex = 5;
  loader = true;
  
  constructor(private whatsnew : WhatsNewService){
    
  }

  ngOnInit(): void {
    //this.getWhatsNewData();
    this.getWhatsNewDetails();
    this.loader = true;

    
  }

  // getWhatsNewData()
  // {
  //   this.whatsnew.getWhatsNewData().subscribe(data=>  {
  //     this.whatsNewRecords = data;
  //     console.log("what's new", data);
  //     console.log("what's new", this.whatsNewRecords);
  //     this.loader = false;
      
  //   })
  // }

  getWhatsNewDetails()
  {
    //Workspace, Good Practices, Resources
    this.whatsnew.getWhatsNewDetails().subscribe(data=>  {
      this.whatsNewAllRecords = data;
      //console.log(this.whatsNewAllRecords);
      this.whatsNewAllRecords = this.whatsNewAllRecords.slice(this.strartIndex,Math.floor(this.endIndex));
      //console.log("what's new all details", this.whatsNewAllRecords);
      this.whatsNewWorkspaceRecords = data.filter(items => items.whatsNewModuleId == 6).slice(this.strartIndex,Math.floor(this.endIndex));
      //console.log("what's new workspace details", this.whatsNewWorkspaceRecords);
      //this.whatsNewGoodPracticesRecords = data.filter(items => items.whatsNewModuleId == 1).slice(this.strartIndex,Math.floor(this.endIndex));
      //console.log("what's new good practices details", this.whatsNewGoodPracticesRecords);
      this.whatsNewResourcesRecords = data.filter(items => items.whatsNewModuleId == 3).slice(this.strartIndex,Math.floor(this.endIndex));
      //console.log("what's new resources details", this.whatsNewResourcesRecords);
      this.whatsNewEventsRecords = data.filter(items => items.whatsNewModuleId == 7).slice(this.strartIndex,Math.floor(this.endIndex));
      //console.log("what's new good practices details", this.whatsNewEventsRecords);
      //this.loader = false;
    })
  }

}
