<div class=" develop-banner">
    <!-- <div class="banner-img">
        <img src="../assets/images/nischal-masand-0606MKTpyM8-unsplash.jpg" alt=""> 
    </div>-->
    <div class="" style="padding-top: 05px;padding-bottom: 05px;">
        <div class="develop-banner-inr">{{briefInfo}}</div>
        <!-- <div class="mb-5"><a href="{{countryUploadTemplateLink}}" target="_blank" class="btn-template-all" style="padding: 0.5rem 1rem;
            font-size: 1.25rem;">Submit Good Practices</a></div> -->
        <div>
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <a class="dropdown-toggle btn-template-all inrButtonSubmit" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                        Submit Good Practices</a>
                    <ul class="dropdown-menu">
                        <li><a href="{{countryUploadTemplateLink}}" target="_blank" class="dropdown-item" role="button">Good Practices</a></li>
                        <li><a href="{{innovativeInitiativesFormLink}}" target="_blank" class="dropdown-item" role="button">Innovative Initiatives</a></li>
                    </ul>
                </li>
            </ul>
        </div>
        <!--<a href="#" class="about-link banner-link" data-bs-toggle="modal"
                          data-bs-target="#LearnModal">Learn more
                          <span><i class="fas fa-chevron-right right-arrow-animation"></i></span>
                      </a>-->

        <!-- <a style="padding-left: 55px;" [routerLink]="['../learn-more/learn-more',{'paramKey': 'briefIntrouctionDesc'}]" target="_blank" class="about-link banner-link">
        Learn More <span><i class="fas fa-chevron-right right-arrow-animation"></i></span>
      </a> -->


        <!--<app-learn-more [message1]="send"></app-learn-more>-->

        <!-- Modal -->
        <!--<div class="modal fade mt-auto" id="LearnModal" tabindex="-1"
  aria-labelledby="LearnModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mnw-700">
      <div class="modal-content ">
          <div class="modal-header">
              <h5 class="modal-title" id="uploadModalLabel">Policy Hub</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <p class="body-lm-text">{{briefInfo_learnMore}}
              </p>
          </div>
          <div class="modal-footer ptb-5">-->
        <!-- <button type="button" class="btn btn-new-primary me-4">Save</button>
  <button type="button" class="btn btn-outline-primary"
      data-bs-dismiss="modal">cancel</button> -->
        <!--</div>
          </div>
      </div>
  </div>-->
    </div>
</div>