import { Component, OnInit, Inject, OnDestroy, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionType, InteractionStatus, PopupRequest, RedirectRequest, AuthenticationResult, AuthError } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { DialogHelperService } from './common/modals/dialog-helper.service';
import { LoaderService } from './common/services/loader.service';
import { LoggingService } from './services/logging/logging.service';
import { NavigationStart, Router } from '@angular/router';
import { MsalUserService } from './common/services/msal-user.service';
import { BrowserConstants } from '@azure/msal-browser/dist/utils/BrowserConstants';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Account, authResponseCallback } from 'msal';
import { DialogResult } from './common/modals/yes.component';
import { NgForm } from '@angular/forms';
import { ToastrService } from './common/services/toastr.service';
import { FeedbackDetailsService } from './services/chatbot-feedbackdetails/feedbackdetails.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  isAuthenticated = false;
  errorMessage: any;
  isSpanish = false;
  isAdmin = false;
  isChatBotOpen = false;
  public cbName: any = "";
  private readonly _destroying$ = new Subject<void>();

  private currentUrl: any = "/";

  public chabotUrl: SafeResourceUrl;
  waitTimeLeft: number = 60;
  interval;

  isFeebackOpen = true;
  isEmailValid = false;
  public cbfName:string="";
  public cbfEmail:string="";
  public cbfSubject:string="";
  public cbfMessage:string="";
  public selectedFiles?: FileList;
  public selectedFileName:string = "";
  public selectedFilePath: string = "";
  public selectedFileData: any;
  submitted:boolean=false;
  feedabckfiles:ChatbotFeebackDetailsFiles[]=[];
  @ViewChild('myForm') ChatbotfeedbackForm;
  @ViewChild('btnSubmit') btnSubmit: ElementRef;
  @ViewChild('closebutton') closebutton;
  @ViewChild('fileUploader') selectfile: ElementRef;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private containerRef: ViewContainerRef,
    private authService: MsalService,
    private dialogService: DialogHelperService,
    private msalBroadcastService: MsalBroadcastService,
    private loggingservice: LoggingService,
    public translate: TranslateService, private loaderService: LoaderService,
    public router: Router, private msalUserService: MsalUserService,
    public sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private feedbackdetailsService: FeedbackDetailsService
  ) {

    this.chabotUrl = environment.chatbotUrl;

    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|da/) ? browserLang : 'en');
    this.dialogService.setViewContainerRef(this.containerRef);
    this.loaderService.setViewContainerRef(this.containerRef);
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit(): void {

    this.chabotUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chatbotUrl);
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.setIsAuthenticated();
        this.msalUserService.setGraphUser();
      })
    this.checkCookies();
    this.login();
  }

  onActivate(e, outlet) {
    outlet.scrollTop = 0;
    outlet.scrollIntoView();
    this.isAdmin = this.router.url.indexOf("/admin") >= 0;
  }
  switchLang(lang: string) {
    this.translate.use(lang);
    this.isSpanish = !this.isSpanish;
  }
  setIsAuthenticated() {
    this.isAuthenticated = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    //console.log("checkAndSetActiveAccount method start");
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      //console.log("checkAndSetActiveAccount method success");
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Redirect) {
      if (this.msalGuardConfig.authRequest) {
        //console.log("login method start");
        let accounts = this.authService.instance.getAllAccounts();

        // if (accounts[0] != null && accounts[0].tenantId == environment.tenantId) {
        if (accounts[0] != null) {
          this.authService.instance.setActiveAccount(accounts[0]);
          try {
            this.authService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
              .subscribe((response: AuthenticationResult) => {
                this.authService.instance.setActiveAccount(response.account);
                this.isAuthenticated = true;
                this.msalUserService.setGraphUser();
                console.log("login method success");
                this.router.navigateByUrl(this.currentUrl);
              },
                (error) => {
                  console.log("login method error  " + error);
                  this.showLoginPage();
                });
          }
          catch (e) {
            console.log("login method catch error " + e);
            if (e.errorCode == BrowserConstants.INVALID_GRANT_ERROR) {
              this.showLoginPage();
            }
          }
        } else {
          this.showLoginPage();
        }
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  private showLoginPage() {
    this.waitTimeLeft = 60;
    this.authService.handleRedirectObservable()
      .subscribe((response: AuthenticationResult) => {
        this.interval = setInterval(() => {
          if (this.waitTimeLeft > 0) {
            this.waitTimeLeft--;
            if (response !== null) {
              clearInterval(this.interval);

              this.authService.instance.setActiveAccount(response.account);
              //this.router.navigateByUrl(this.currentUrl);
            }
          }
          else {
            var message = `We are having some trouble in login, please enable cookies of your browser.`
            //this.dialogService.showYesDialog("Login", message, "", false).subscribe
            //  (x => {
              //  if (x == DialogResult.YES) {
                console.log(message)
                  clearInterval(this.interval);
                  window.location.reload();
           //    }
         //     });
          }
        }, 1000)
      },
        (error) => {
         // console.log("showLoginPage method error  " + error)
          var message = `We are having some trouble in login, please enable cookies of your browser.`
         // this.dialogService.showYesDialog("Login", message, "", false).subscribe
         //   (x => {
           //   if (x == DialogResult.YES) {
            console.log(message)
                clearInterval(this.interval);
                window.location.reload();
           //   }
          //  });
        });
  }

  /*   login() {
  
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
  
          let accounts = this.authService.instance.getAllAccounts();
         // if (accounts[0] != null && accounts[0].tenantId == environment.tenantId) {
          if (accounts[0] != null) {
            this.authService.instance.setActiveAccount(accounts[0]);
            try{
            this.authService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as PopupRequest)
              .subscribe((response: AuthenticationResult) => {
                this.authService.instance.setActiveAccount(response.account);
                this.isAuthenticated = true;
                this.msalUserService.setGraphUser();
              });
            }
            catch(e){
              if(e.errorCode == BrowserConstants.INVALID_GRANT_ERROR){
                  this.showLoginPopup();
              }
            }
          } else {
            this.showLoginPopup();
          }
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
          this.authService.loginRedirect();
        }
      }
    }
  
    private showLoginPopup(){
      this.authService.loginPopup()
      .subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
        this.router.navigateByUrl(this.currentUrl);
      });
    }*/

  openChatBot() {
    this.isChatBotOpen = true;
    this.chabotUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chatbotUrl);
  }

  closeChatBot() {
    this.chabotUrl = "";
    this.isChatBotOpen = false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  checkCookies() {
    let cc = window as any;
    /*cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#0033a0"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: "This website uses cookies to improve your experience.",
        dismiss: "close",
        link: " Click here how to change your settings.",
        href: "enablecookies/enablecookies;paramKey=enablecookies"
      }
    });*/

    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#0033a0'
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: 'classic',
      /*type: 'opt-out',
      layout: 'my-custom-layout',
      layouts: {
        "my-custom-layout": '{{messagelink}}{{compliance}}'
      },*/
      elements:{
        messagelink: `
        <span id="cookieconsent:desc" class="cc-message">{{message}} 
          This website uses cookies to improve your experience.
          <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>

          <br/> 
          This page can be translated into multiple languages.
          <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{translationHref}}" target="_blank" rel="noopener">{{translationLink}}</a>
                    
        </span>
        `,
      },
      content:{
        message: '',
        dismiss: "close",
        
        cookiePolicyLink: 'Click here how to change your settings.',
        cookiePolicyHref: 'enablecookies/enablecookies;paramKey=enablecookies',
    
        translationLink: 'Click here how to translate.',
        translationHref: '../assets/documents/POEM - Browser Translation Guide.pdf',
    
      }
    }); 
  }
  resetData(ChatbotfeedbackForm:NgForm) {
    this.cbfName = "";
    this.cbfEmail = "";
    this.cbfSubject = "";
    this.cbfMessage = "";
    this.selectedFilePath = "";
    this.selectedFileName = "";
    this.selectedFileData = "";
    this.selectfile.nativeElement.value = null;
    this.feedabckfiles = [];
    ChatbotfeedbackForm.reset();
  }
  validateEmail() {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var email = document.getElementById("moderatorEmail") as HTMLInputElement;
    if (!pattern.test(email.value)) {
      this.isEmailValid = false;
    } else {
      this.isEmailValid = true;
    }
  }
  onFileChange(event) {
    this.selectedFiles = event.target.files;
    //for (let i = 0; i < this.selectedFiles.length; i++) 
    //{
        var file = this.selectedFiles[0]
        const reader = new FileReader();
        reader.readAsDataURL(file);
        this.selectedFileName = file.name;
        var ext = file.name.split('.').pop();
        var extensions = ["doc", "docx", "xl", "xls", "xlsx", "pdf", "ppt", "pptx", "txt", "jpg", "png"];
        if (!extensions.includes(ext)) {
          this.toastrService.showError('Selected file type is not supported.');
          
          this.selectedFilePath = "";
          this.selectedFileName = '';
          this.selectedFileData = '';
          let el = this.selectfile.nativeElement;
          el.value = "";
          return;
        }
        reader.onload = (e) => {
          let DocumentImageLink = reader.result as string;
          this.selectedFileData = DocumentImageLink.split("base64,")[1]; 
          
        };
    //}
  }

  add_file() {
    const feedbackdoument = new ChatbotFeebackDetailsFiles();
    feedbackdoument.FeebackDetailsId = "0";
    feedbackdoument.createdBy = "";
    feedbackdoument.DocumentName = this.selectedFileName;
    feedbackdoument.DocumentPath = this.selectedFileData;
    let el = this.selectfile.nativeElement;
    el.value = "";
    this.feedabckfiles.push(feedbackdoument);
    this.selectedFileName = "";
    console.log(this.feedabckfiles);
  }
  removeObjective(i: number) {
    this.feedabckfiles.splice(i, 1);
  }
  
  saveFeedbackDetails(ChatbotfeedbackForm: NgForm): any {
    let el: HTMLButtonElement = this.btnSubmit.nativeElement;
    el.disabled = true;
    const data = {
      Name: this.cbfName,
      Email: this.cbfEmail,
      Subject: this.cbfSubject,
      Message: this.cbfMessage,
      Attachments: this.feedabckfiles
    };
    console.log(data);
    this.loaderService.showLoader();
    this.feedbackdetailsService.saveFeedbackDetails(data).subscribe(response => {
      var res = response;
      this.closebutton.nativeElement.click();
      this.toastrService.showSuccess("Details sent to the POEM Administrator/s successfully.");
    }, err => {
      this.loaderService.hideLoader();
      el.disabled = false;
      this.closebutton.nativeElement.click();
    this.toastrService.showError("Error : " + err.message)
    });
    
    this.submitted = true;
    this.loaderService.hideLoader();
    setTimeout(function() {
      this.submitted = false;          
    }.bind(this), 5000); 
    this.resetData(ChatbotfeedbackForm);
    this.isFeebackOpen = true;
  }
}
export class FeedbackDetailsInfo {
  Name: string;
  Email: string;
  Subject: string;
  Message:string;
  uploadFeedbackDetailsDocuments: ChatbotFeebackDetailsFiles[] = [];
}
export class ChatbotFeebackDetailsFiles {
  DocumentPath: string;
  DocumentName: string;
  FeebackDetailsId:string;
  createdBy: string 
}
