<div *ngIf="isAuthenticated">
  <app-header [isAdmin]="isAdmin"></app-header>
  <router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>
 
  <app-footer></app-footer>
</div>

<!-- <div class="chatbot">

  <img [ngClass]="isChatBotOpen?'hide':'show'" src="../assets/images/ChatBotIcon.svg" alt="" class="chatbot-open" (click)="openChatBot()">

  <div class="chatbot-wrap" [ngClass]="isChatBotOpen?'show':'hide'">

    <div class="chatbot-head d-flex justify-content-between">

      <div class="chatbot-brand d-flex align-items-center">

        <img src="../assets/images/ChatBot-headerIcon.svg" alt="" class="me-3">

        <p class="mb-0 chatbot-head-title">POEM</p>

      </div>

      <button class="chatbot-close" (click)="closeChatBot()"><i class="fas fa-times"></i></button>


    </div>

    <div class="chatbot-body">

      <iframe [src]="chabotUrl"></iframe>

    </div>

  </div>

</div> -->
<div class="chatbotfeedback" *ngIf="isFeebackOpen">
  <p class="text-end mb-4">
    <a type="button" data-bs-toggle="modal"
              data-bs-target="#feedbackModal" (click)="openChatBot()">
              <img [ngClass]="isChatBotOpen?'hide':'show'" src="../assets/images/ChatBotIcon.svg" alt="" class="chatbot-open" >
    </a>
  </p>
</div>

<!-- Upload Modal -->
<div class="modal fade mt-auto" id="feedbackModal" tabindex="-1"
     aria-labelledby="uploadModalLabel" aria-hidden="true">
  <form class="row g-3 mt-2 pdad-admin-form" novalidate
     #ChatbotfeedbackForm="ngForm">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content"> 
        <div class="modal-header">
          <h5 class="modal-title">
            Contact Us
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close" (click)="closeChatBot()"></button>
        </div>
        <div class="modal-body">

          <div class="row">

            <div class="col-sm-6">
              <div class="date-cal">
                <label for="Name" class="form-label">Name</label>
                <input required  [(ngModel)]="cbfName" #name="ngModel"
                    maxlength="100" type="text"  class="form-control" name="txtName">
                <div *ngIf="name.errors && (name.dirty || name.touched)"
                    class="link-danger mb-0">
                    Please enter name
                </div>
                
              </div>
            </div>
            <div class="col-sm-6">
              <div class="date-cal">
                <label for="Email" class="form-label">Email</label>
                <input required  [(ngModel)]="cbfEmail" #email="ngModel"
                  maxlength="100" type="text" class="form-control" name="txtEmail"
                  pattern="^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$">
                <!-- <div *ngIf="email.errors && (email.dirty || email.touched)"
                  class="link-danger mb-0">
                  Please enter Email
                </div> -->
                <div *ngIf="email.errors" [ngClass]="'error'" (change)="validateEmail()">

                  <div *ngIf="(email.dirty || email.touched) || email.errors.pattern" style="color:#dc3545">
                    Email format is not valid.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="date-cal">
                <label for="Subject" class="form-label">Subject</label>
                <input required  [(ngModel)]="cbfSubject" #subject="ngModel"
                        maxlength="100" type="text" class="form-control" name="txtSubject">
                <div *ngIf="subject.errors && (subject.dirty || subject.touched)"
                        class="link-danger mb-0">
                        Please enter subject
                </div>
                
              </div>
            </div>
            <div class="col-sm-6">
              <div class="date-cal">
                <label for="Message" class="form-label">Message</label>
                <textarea required class="form-control" [(ngModel)]="cbfMessage" #message="ngModel" 
                  maxlength="250" name="txtMessage"
                  id="txtMessage" rows="3"></textarea>
                <div *ngIf="message.errors && (message.dirty || message.touched)"
                  class="link-danger mb-0">
                  Please enter message
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div>
                <label for="Attachment"
                       class="form-label">Attachment</label>
                <div class="d-flex align-items-center">
  
                  <input class="form-control"
                         type="file"
                         #fileUploader
                         id="selectfileValue"
                         (change)="onFileChange($event)"
                         accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*,
                                .doc,.docx,.xl,.xls,.xlsx,.pdf,.ppt,.pptx" />&nbsp;&nbsp;
                  
                  <button type="submit" style="float: right;width:30%;
                margin-top: -3px;
                margin-right: -100px;"
                        class="btn btn-outline-primary new-btn"
                        (click)="add_file()"
                        [disabled]="(selectedFileName == '')">
                  Add File
                </button>
                </div>
              </div>
              <div *ngIf="feedabckfiles.length > 0" class="input-badge mt-3 flex-wrap d-flex">
                <div *ngFor="let file of feedabckfiles; let i = index;">
                  <!--<span class="badge bg-light text-dark"></span>-->
                  <!--<i class="fas fa-times"></i>-->
  
                  <label  class="badge bg-light text-dark">
                    <span>
                      {{file.DocumentName}}
                    </span>
                    <a (click)="removeObjective(i)" style="text-decoration:none">
                      <i class="fas fa-times"></i>
                    </a>
                  </label>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <div class="date-cal">
                <label for="Message" class="form-label">Attachment</label>
                <input required class="form-control"
                        type="file"
                        #fileUploader
                        id="selectfileValue"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*,
                                .doc,.docx,.xl,.xls,.xlsx,.pdf,.ppt,.pptx" 
                        (change)="onFileChange($event)" 
                        maxFileSize="1000000000"/>
                <div>
                <button type="submit" style="float: right;
                margin-top: -40px;
                margin-right: -100px;"
                        class="btn btn-outline-primary new-btn"
                        (click)="add_file()"
                        [disabled]="(selectedFileName == '')">
                  Add File
                </button>
                <div *ngIf="feedabckfiles.length > 0" class="input-badge mt-3 flex-wrap d-flex">
                  <div *ngFor="let file of feedabckfiles; let i = index;">
                    
                    <label class="badge bg-light text-dark">
                      <a (click)="removeObjective(i)" style="text-decoration:none">
                        <i class="fas fa-times"></i>
                      </a>
                      <span>
                        {{file.DocumentName}}
                      </span>

                      
                    </label>
                    
                  </div>
                </div>
              </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer mx-auto py-20">
          <button #btnSubmit
                  type="button"
                  class="btn btn-new-primary me-4"
                  [disabled]="(cbfName == '' || cbfEmail == '' || cbfSubject == '' || cbfMessage == '')"
                  (click) = "saveFeedbackDetails(ChatbotfeedbackForm)">
            SUBMIT
          </button>
          <button type="button" #closebutton class="close" data-bs-dismiss="modal" hidden>&times;</button>
          <button type="button" class="btn btn-outline-primary" (click)="resetData(ChatbotfeedbackForm)">
            RESET
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

