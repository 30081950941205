import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from '../app/landing-page/landing-page.component'
import { AuthGuardService } from './common/guards/auth-guard.service';
import { Roles } from './constants/constants';
import { EnablecookiesComponent } from './enablecookies/enablecookies.component';
import { LearnMoreComponent } from './landing-page/learn-more/learn-more.component';
import { WhatsNewPageComponent } from './landing-page/whats-new-page/whats-new-page.component';

const routes: Routes = [
  {
    path: 'admin', loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule),
    canLoad: [AuthGuardService], data: { roles: [Roles.Admin] }
  },
  {
    path: 'virtual-workspace', loadChildren: () => import('../app/virtual-workspace/virtual-workspace.module').then(m => m.VirtualWorkspaceModule),
    canLoad: [AuthGuardService], data: { roles: [Roles.Admin, Roles.IOMUser] }
  },
  { path: 'landing-page', component: LandingPageComponent }, //canDeactivate: [ConfirmCloseGuard]  },
  {
    path: 'learn-more/:parentName', component: LearnMoreComponent
  },
  { path: 'enablecookies/:parentName', component: EnablecookiesComponent },
  {
    //pdad is not being used - this load children from _pdad
    path: 'pdad', loadChildren: () => import('../app/_pdad/pdad.module').then(m => m._PDADModule),
    canLoad: [AuthGuardService], data: { roles: [Roles.Admin, Roles.IOMUser] }
  },
  {
    path: '_pdad', loadChildren: () => import('../app/_pdad/pdad.module').then(m => m._PDADModule),
    canLoad: [AuthGuardService], data: { roles: [Roles.Admin, Roles.IOMUser] }
  },
  {
    path: 'knowledge-sharing', loadChildren: () => import('../app/knowledge-sharing/knowledge-sharing.module').then(m => m.KnowledgeSharingModule),
    canLoad: [AuthGuardService], data: { roles: [Roles.Admin, Roles.IOMUser] }
  },
  { path: '', redirectTo: '/landing-page', pathMatch: 'full' },
  { path: 'whats-new-page', component:WhatsNewPageComponent },

];

const routerOptions: ExtraOptions = {
  //scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  preloadingStrategy: PreloadAllModules
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
