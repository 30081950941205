<section class="py-5 mb-5 upcoming-events">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
          <div class="row m-0">
            <div class="col-lg-6">
              <h3>Upcoming Events</h3>
            </div>
          </div>
          <div class="carousel-inner" *ngIf="eventRecords.length > 0">
            <!-- For autoplay - automatic scroll use below property. -->
            <!-- autoplayInterval="3000" -->
            <p-carousel [value]="eventRecords" [numVisible]="2" [circular]="true" [numScroll]="1" [showIndicators]=false
              styleClass="items-carousel" [responsiveOptions]="responsiveOptions">
              <ng-template let-eventRecord pTemplate="item">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="d-flex align-items-center upcoming-events_row">
                        <div class="upcoming-events_calendar-container">
                          <div class="upcoming-events_calendar">
                            <h4 class="date">{{eventRecord.eventDate | date:'dd'}}
                              <span class="month mt-1">{{eventRecord.eventDate | date:'MMM'}}</span>
                            </h4>
                          </div>
                        </div>
                        <div class="upcoming-details-container">
                          <h6 class="text-truncate" style="font-weight:600;" data-toggle="tooltip" [title]="eventRecord.eventsTitle">
                            {{ (eventRecord.eventsTitle.length > 35) ? (eventRecord.eventsTitle | slice:0:32)+'...':
                            (eventRecord.eventsTitle) }}</h6>
                          <!-- <p kendoTooltip style="padding-bottom: 10px;" position="bottom" title="Saves the current document">{{eventRecord.eventDescription}}</p> -->
                          <p style="text-overflow: ellipsis; -webkit-line-clamp: 2; line-clamp: 2;"
                            data-toggle="tooltip" [title]="eventRecord.eventDescription">
                            {{ (eventRecord.eventDescription.length > 70) ? (eventRecord.eventDescription |
                            slice:0:67)+'...': (eventRecord.eventDescription) }}
                          </p>
                        </div>
                        <div class="upcoming-events_more-container disabledSpan" (click)="showEvents(eventRecord.eventId)">
                          <span (click)="showEvents(eventRecord.eventId)">
                            <img src="../../../assets/images/arrow-right-grey.svg" alt="no img"
                              (click)="showEvents(eventRecord.eventId)">
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>
          <div *ngIf="eventRecords.length == 0">
            There are no new upcoming events.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal mt-auto" id="eventlandModal" [style.display]="showModal ? 'block' : 'none'" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mnw-700">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #ECF6FF;">
          <h5 class="modal-title" id="uploadModalLabel">Events</h5>
          <button type="button" class="btn-close" style="background-color:  #ECF6FF;" data-dismiss="modal"
            (click)="hide()" aria-label="Close"></button>
        </div>
        <div class="modal-body p-30" style="background-color:  #FFFFFF;">
          <div class="row gx-5">
            <div class="col-md-2 pe-3">
              <div class="news-icon pt-2">
                <p class="mb-0 mx-auto" style="font-size:31px;">
                  {{eventDate | date:'dd'}}
                  <span class="month">{{eventDate | date:'MMM'}}</span>
                </p>
              </div>
            </div>
            <div class="col-md-10 ps-0">
              <div class="news-content p-2">
                <p class="news-title mb-0">{{eventsTitle}}</p>
                <p class="news-writup mb-0" data-toggle="tooltip" [title]="eventDescription">
                  {{eventDescription}}
                </p>
              </div>
              <button (click)="gotoEvent(eventLink)" class="btn btn-new btn-bg-white event-pop-btn mt-3"><i
                  class="fas fa-link me-2"></i>Click
                here to join
                event</button>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="background-color:  #FFFFFF;">
        </div>
      </div>
    </div>
  </div>
</section>