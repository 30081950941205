<div class="row">
  <div class="col-lg-5">
    <div class="h-100">
      <img src="../assets/images/virtual-workspace.jpg" alt="no img" style="width: 100%; height: 100%;">
    </div>
  </div>
  <div class="col-lg-7">
    <div class="d-flex align-items-center h-100">
      <div class="inner-section">
        <h1>The Virtual Workspace</h1>
        <p class="my-3">To facilitate joint work, engage in thematic discussions and ensure organizational learning on
          policies and programs developed in IOM.
        </p>
        <div class="">
          <button class="active w-100 position-relative custom-tooltip_container"
            [routerLink]="['../virtual-workspace/workspace-list', sectionConstant.Community_Of_Practice]">Communities of
            Practice
            <span class="custom-tooltip_white">Engage with communities of practices.</span>
          </button>
        </div>
        <div class="btn-group d-inline">
            <div class="row">
              <div class="col-lg-6">
              <button class="position-relative custom-tooltip_container w-100"
                [routerLink]="['../virtual-workspace/workspace-create', sectionConstant.Request_Workspace]">Request a Community of Practice
                <span class="custom-tooltip_white">Request a new community of practice or link an existing one</span>
              </button>
            </div>
            <div class="col-lg-6">
              <button class="position-relative custom-tooltip_container w-100"
                [routerLink]="['../virtual-workspace/workspace-list', sectionConstant.My_Workspaces]">
                My Community of Practice
                <span class="custom-tooltip_white">View your subsribed communities of practice.</span>
              </button>
            </div>
            <div class="col-lg-6">
              <button class="position-relative custom-tooltip_container w-100"
                [routerLink]="['../knowledge-sharing/peer-to-peer-learning', sectionConstantKnowledgeSharing.Peer_to_Peer_Learning]">Viva
                Engage / Yammer
                <span class="custom-tooltip_white">Engage with existing Viva Engage communities, connect with staff members
                  in the organization</span>
              </button>
            </div>
            <div class="col-lg-6">
              <a target="_blank" href="https://www.sparkblue.org/search/all/iom"><button
                class="position-relative custom-tooltip_container w-100">IOM Discussions on SparkBlue
                <span class="custom-tooltip_white">
                  IOM Discussions on SparkBlue</span>
              </button></a>
            </div>
            </div>
        </div>
        <div class="input-group input-item">
          <span id="virtual-addon1"> Find a Community of Practice <i class="ps-1">|</i> </span>
          <input type="text" class="input-text" id="searchWorkspace" (keydown.enter)="loadWorkspaceData()"
            [(ngModel)]="searchText" placeholder="Type a search criteria" aria-label="Username" 
            #search (keyup)="displayPopup()" aria-describedby="basic-addon1">
            <div class="search-country position-absolute">
              <kendo-popup [anchor]="search" *ngIf="showPopup" popupClass="inner-wrapper"
                  class="country-popup" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign">
                  <div *ngIf="!loading" class="content">
                      <div *ngFor="let item of searchFilterData;let j=index"
                          (click)="selectWorkspace(item)"
                          class="country-fil-col d-flex justify-content-between align-items-center">
                          <p class="mb-0"> {{ item.policyname }}</p>
                      </div>
                      <div *ngIf="searchText.trim() != '' && searchText.length > 2 && searchData.length == 0 "
                          class="country-fil-col d-flex justify-content-between align-items-center">
                          <span class="no-match box-shadow-none dec-action">We didn't find any
                              matches.</span>
                      </div>
                  </div>
              </kendo-popup>
          </div> 
          <span class="disabledSpan" [ngClass]="{'disabledSpan': searchText.trim() == ''}" id="virtual-addon2"
            [routerLink]="['../virtual-workspace/find-workspace', sectionConstant.Find_Workspace,searchText]"
            tabindex="0">
            <i class="fas fa-search"></i></span>
        </div>
      </div>
    </div>
  </div>
</div>