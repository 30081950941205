import { Component, NgModule, OnInit } from '@angular/core';
import { LandingSectionService } from '../../../app/services/landing-section/landing-section.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brief-info',
  templateUrl: './brief-info.component.html',
  styleUrls: ['./brief-info.component.css'],  
})

export class BriefInfoComponent implements OnInit {
  briefInfo: any;
  public countryUploadTemplateLink = environment.countryUploadTemplateLink;
  public innovativeInitiativesFormLink = environment.innovativeInitiativesFormLink;
  constructor(private landingSectionService: LandingSectionService) {
  }

  ngOnInit(): void {
    this.getBriefInfo();
  }

  getBriefInfo() : any
  {
     this.landingSectionService.getLandingSectionDetails().subscribe(data =>
      {

       if (data[0]?.briefIntrouction != null) {
         localStorage.setItem("briefIntroDesc", data[0].briefIntrouctionDesc);
         localStorage.setItem("briefIntroImagePath", data[0].briefIntrouctionImagePath);
         this.briefInfo = data[0].briefIntrouction;
       }
     })
  }

}
