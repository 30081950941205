import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  eventRecords: any;
  showModal: boolean;
  eventsTitle: any;
  eventDescription: any;
  eventDate: any;
  eventRecordsById: any;
  eventLink: any;
  responsiveOptions;

  constructor(private events: EventsService) {
    this.responsiveOptions = [{
      breakpoint: '1024px',
      numVisible: 1,
      numScroll: 3
    }];
  }

  ngOnInit(): void {
    this.events.getEventsData(null).subscribe(data => {
      this.eventRecords = data;
    })
  }

  hide() {
    this.showModal = false;
  }

  showEvents(eventId) {
    this.eventRecordsById = this.eventRecords.find(c => c.eventId === eventId);
    this.showModal = true;
    this.eventsTitle = this.eventRecordsById.eventsTitle;
    this.eventDescription = this.eventRecordsById.eventDescription;
    this.eventDate = this.eventRecordsById.eventDate;
    this.eventLink = this.eventRecordsById.eventLink;
  }

  gotoEvent(eventLink) {
    window.open(eventLink, '_blank');
  }

}
