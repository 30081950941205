
  
<kendo-scrollview #sv [data]="slides" class="slide-show-class"
                  [arrows]="true"
                  [pageable]="true"
                  [endless]="endless">
  <ng-template let-item="item">
    <img src="{{ item?.slideshowImagePath + appendToUrl }}"
         alt="..."
         [ngStyle]="{ minWidth: width }"
         draggable="false" style="opacity:2;" />
  </ng-template>
</kendo-scrollview>

   
