<header [ngClass]="{'admin-header' : isAdmin}"
    (click)="showSubMenuVirtualWorkspace = false; showSubMenuKnowledgeSharing = false">
    <div class="bg-filter"></div>
    <div class="container d-flex justify-content-between align-items-center" *ngIf="!isAdmin">
        <div class="d-flex align-items-center">
            <div class="brand d-flex align-items-center">
                <div>
                    <a routerLink='landing-page' fragment="landing-page">
                        <img src="../../assets/images/Logo_POEM-Blue.svg" alt="" class="w-100">
                    </a>
                </div>

                <!-- <p class="mb-0">policy hub</p> -->
            </div>
            <!-- <div class="language d-flex justify-content-between align-items-center">
                <a href="#" class="english-lag active">English</a>
                <a href="#" class="spanish-lag">Spanish</a>
            </div> -->
            <div class="input-group">
                <span><img src="../../assets/images/search-grey.svg" alt="no img"></span>
                <input type="text" [(ngModel)]="searchInText" placeholder="Type something" style="font-size: small;" (keydown.enter)="filterSearch()">
                <!-- (change)="filterSearch()"  -->
                <select [(ngModel)]="selectSearchFilter" style="font-size: small;">
                    <!--<option selected value="0">-- Select --</option>-->
                    <option selected value="1">Community of Practice</option>
                    <option value="2">Good Practice</option>
                    <option value="3">Resources</option>
                </select>
                <span class="clr-text" style="cursor: pointer;">
                    <img src="../../assets/images/search-white.svg" alt="no img" (click)="filterSearch()">
                </span>            
            </div>
            <!--<a target="_blank" href="#">            
                <span class="custom-tooltip_container">
                    <img src="../../assets/images/translate.png" alt="translate" style="width: 35px;" />
                    <span class="custom-tooltip_white">Click here to download guide and learn how to translate this site to another language using your browser.</span>
                </span>            
            </a>-->
        </div>

        <app-profile></app-profile>

        


    </div>
    <div *ngIf="!isAdmin">
        <section>
            <div class="">
                <div class="row">
                    <nav class="navbar navbar-expand-lg theme-header">
                        <div class="container p-0 py-1">
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <!--a class="nav-link" role="button" (click)="goToGlobal($event)">Global</a-->
                                        <a routerLink='landing-page' fragment="landing-page" class="nav-link" role="button" >Home</a>
                                    </li>
                                    <li class="nav-item dropdown" (mouseenter)="openRegionSubMenu($event)"
                                        (mouseleave)="closeRegionSubMenu($event)">
                                        <a class="nav-link dropdown-toggle" href="#" role="button"
                                            data-bs-toggle="dropdown" aria-expanded="showSubMenuRegion"
                                            [ngClass]="showSubMenuRegion ? 'show' : ''">Region</a>
                                        <ul class="dropdown-menu country-list_dropdown"
                                            [ngClass]="showSubMenuRegion ? 'show' : ''">
                                            <!-- <li>
                                                <div class="px-3 py-2">
                                                    <div class="d-flex position-relative">
                                                        <img src="../../assets/images/search-grey.svg" alt="no img">
                                                        <input class="me-2" type="text" placeholder="Search Country"
                                                            [(ngModel)]="searchText" (keyup)="searchCountry()">
                                                    </div>
                                                </div>
                                            </li> -->
                                            <div class="country-scroll">
                                                <li *ngFor="let region of regionList;let j=index">
                                                    <a class="dropdown-item"
                                                        (click)="goToRegion(region.regionName, $event)">{{region.regionName}}</a>
                                                </li>
                                            </div>
                                        </ul>
                                    </li>
                                    <li class="nav-item dropdown" (mouseenter)="openCountrySubMenu($event)"
                                        (mouseleave)="closeCountrySubMenu($event)">
                                        <a class="nav-link dropdown-toggle" href="#" role="button"
                                            data-bs-toggle="dropdown" aria-expanded="showSubMenuCountry"
                                            [ngClass]="showSubMenuCountry ? 'show' : ''">
                                            Countries
                                        </a>
                                        <ul class="dropdown-menu country-list_dropdown"
                                            [ngClass]="showSubMenuCountry ? 'show' : ''">
                                            <li>
                                                <div class="px-3 py-2">
                                                    <div class="d-flex position-relative">
                                                        <img src="../../assets/images/search-grey.svg" alt="no img">
                                                        <input class="me-2" type="text" placeholder="Search Country"
                                                            [(ngModel)]="searchText" (keyup)="searchCountry()">
                                                    </div>
                                                </div>
                                            </li>
                                            <div class="country-scroll">
                                                <li *ngFor="let country of countries;let j=index">
                                                    <a class="dropdown-item"
                                                        (click)="goToCountry(country.countryName, $event)">{{country.countryName}}</a>
                                                </li>
                                            </div>
                                        </ul>
                                    </li>
                                    <li class="nav-item dropdown" (mouseenter)="openVirtualWorkspaceSubMenu($event)"
                                        (mouseleave)="closeVirtualWorkspaceSubMenu($event)">
                                        <a class="nav-link dropdown-toggle"
                                            [ngClass]="showSubMenuVirtualWorkspace ? 'show' : ''" role="button"
                                            data-bs-toggle="dropdown" aria-expanded="showSubMenuVirtualWorkspace"
                                            [routerLink]='"landing-page"' [fragment]='"virtual-workspace"'>
                                            Virtual Workspace
                                        </a>
                                        <ul *ngIf="showSubMenuVirtualWorkspace"
                                            class="dropdown-menu country-list_dropdown"
                                            [ngClass]="showSubMenuVirtualWorkspace ? 'show' : ''">
                                            <li><a class="dropdown-item"
                                                    href="/virtual-workspace/workspace-list/Communities%20of%20Practice"
                                                    (click)="($event.stopPropagation())">Communities
                                                    of Practice</a></li>
                                            <li><a class="dropdown-item"
                                                    href="/virtual-workspace/workspace-create/Request%20a%20Workspace"
                                                    (click)="($event.stopPropagation())">Request a Community of
                                                    Practice</a></li>
                                            <li><a class="dropdown-item"
                                                    href="/virtual-workspace/workspace-list/My%20Workspaces"
                                                    (click)="($event.stopPropagation())">My Community of Practice</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    [routerLink]="['../knowledge-sharing/peer-to-peer-learning', sectionConstantKnowledgeSharing.Peer_to_Peer_Learning]">Viva
                                                    Engage / Yammer</a></li>
                                            <li><a class="dropdown-item"
                                                target="_blank" href="https://www.sparkblue.org/search/all/iom" (click)="($event.stopPropagation())">IOM Discussions on SparkBlue</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item dropdown"
                                        (mouseenter)="openLessonsLearnedAnalysisDataSubMenu($event)"
                                        (mouseleave)="closeLessonsLearnedAnalysisDataSubMenu($event)">
                                        <!-- /landing-page#policy-development -->
                                        <a class="nav-link dropdown-toggle"
                                            [ngClass]="showSubMenuLessonsLearnedAnalysisData ? 'show' : ''"
                                            [routerLink]='"landing-page"' [fragment]='"policy-development"'
                                            role="button" data-bs-toggle="dropdown"
                                            aria-expanded="showSubMenuLessonsLearnedAnalysisData">
                                            Knowledge Sharing
                                            <!--Knowledge Sharing of Good Practices & Innovation--></a>
                                        <ul *ngIf="showSubMenuLessonsLearnedAnalysisData"
                                            class="dropdown-menu country-list_dropdown"
                                            [ngClass]="showSubMenuLessonsLearnedAnalysisData ? 'show' : ''">
                                            <li><a class="dropdown-item" role="button"
                                                    (click)="goToGoodPracticesAndReloadRoute()">Good Practices</a></li>
                                            <li><a class="dropdown-item" href="{{landingPageInnovativeInitiative}}"
                                                    target="_self" (click)="($event.stopPropagation())">Innovative
                                                    Initiatives</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item dropdown" (mouseenter)="openKnowledgeSharingSubMenu($event)"
                                        (mouseleave)="closeKnowledgeSharingSubMenu($event)">
                                        <a class="nav-link dropdown-toggle"
                                            [ngClass]="showSubMenuKnowledgeSharing ? 'show' : ''"
                                            [routerLink]='"landing-page"' [fragment]='"knowledge-sharing"' role="button"
                                            data-bs-toggle="dropdown" aria-expanded="showSubMenuKnowledgeSharing">
                                            Resources and Portals
                                        </a>
                                        <ul *ngIf="showSubMenuKnowledgeSharing"
                                            class="dropdown-menu country-list_dropdown"
                                            [ngClass]="showSubMenuKnowledgeSharing ? 'show' : ''">
                                            <!-- <li><a class="dropdown-item" href="#">Training LMS</a></li> -->
                                            <li><a class="dropdown-item" href="/knowledge-sharing/resources/Resources"
                                                    (click)="($event.stopPropagation())">Toolkits, Guidelines and
                                                    Platforms</a></li>
                                            <li><a class="dropdown-item" target="_blank"
                                                    href="https://iomint.sharepoint.com/teams/InnovationandKnowledgeManagementWorkingGroup/Shared%20Documents/Forms/AllItems.aspx?ct=1693280452569&or=Teams%2DHL&ga=1&LOF=1&id=%2fteams%2fInnovationandKnowledgeManagementWorkingGroup%2fShared%20Documents%2fGeneral%20KMI%20Working%20Group%2fIOM%20Guide%20to%20Knowledge%20Platforms%2f%28Internal%20use%29%20Guide%20to%20Knowledge%20Platforms%2Epdf&parent=%2fteams%2fInnovationandKnowledgeManagementWorkingGroup%2fShared%20Documents%2fGeneral%20KMI%20Working%20Group%2fIOM%20Guide%20to%20Knowledge%20Platforms"
                                                    (click)="($event.stopPropagation())">Guide
                                                    to Knowledge Portal</a>
                                            </li>
                                            <li><a class="dropdown-item" href="/knowledge-sharing/training/Training"
                                                    (click)="($event.stopPropagation())">Training</a></li>
                                            <li><a class="dropdown-item" target="_blank"
                                                    href="https://migrationnetwork.un.org/hub/experts-database"
                                                    (click)="($event.stopPropagation())">Connect
                                                    with Experts</a></li>
                                        </ul>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a class="nav-link"
                                            href="https://iomint.sharepoint.com/sites/MultiLangPOC-IKM/poem/Good%20Practices%20Templates/Forms/AllItems.aspx"
                                            onclick="window.open('https://iomint.sharepoint.com/sites/MultiLangPOC-IKM/poem/Good%20Practices%20Templates/Forms/AllItems.aspx','_blank');"
                                            target="_blank">Template</a>
                                    </li> -->
                                    <li class="nav-item" *ngIf="isAdminRequired">
                                        <a class="nav-link" aria-current="page" [routerLink]='"admin"'
                                            [fragment]='"admin"'>Admin</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </section>
    </div>

    <div class="d-flex justify-content-between align-items-center admin-header-div" *ngIf="isAdmin">

        <div class="admin-brand d-flex align-items-center">
            <div>
                <a routerLink='landing-page' fragment="landing-page">
                    <img src="../../assets/images/Logo_POEM-White.svg" alt="" class="w-100">
                </a>
            </div>
            <!-- <p class="mb-0">policy hub</p> -->
        </div>

        <div class="toggle-menu" (click)=openSidebar()>
            <span></span>
        </div>
        <app-profile></app-profile>

    </div>

    
</header>