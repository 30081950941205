import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {FeedbackService} from '../../services/feedback/feedback.service';
import {MsalUserService} from '../../common/services/msal-user.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']  
})
export class FeedbackComponent implements OnInit {
  txtFeedback = '';
  feedbackRecords:any;  
  submitted=false;

  constructor(private feedback:FeedbackService,private msal:MsalUserService) { }

  ngOnInit(): void {
    this.feedback.getFeedbackData().subscribe(data=>  {
      this.feedbackRecords = data.value;
    })
  }

  onChange(){ }  

  onFeedBackClick(event) {
    event.preventDefault();
    window.open("https://iom.eu.qualtrics.com/jfe/form/SV_3JyXXe9s5C8lzSK", '_blank');
  }

  //onSubmit(feedbackForm:NgForm): void {
  //  const data = {
  //    detail: this.txtFeedback.trim()          
  //  };

  //    this.submitted = true;
  //    //wait 3 Seconds and hide
  //    setTimeout(function() {
  //        this.submitted = false;          
  //    }.bind(this), 5000);    

  //  this.feedback.saveFeedbackData(data)
  //    .subscribe(
  //      response => {
  //        var res=response;
  //        this.txtFeedback='';          
  //      },
  //      error => {
  //        var er=error;
  //      });
  //}

  omit_space_char_atstart(event)
  {   
    var k=event.charCode;
    if((this.txtFeedback=='' || this.txtFeedback.trim().length==0) && k==32)
    {      
      return false;          
    }    
  }    
}
