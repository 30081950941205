/// <reference path="landing-section.service.spec.ts" />
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingSectionService {

  constructor(private httpClient : HttpClient) { }

  getLandingSectionDetails() :Observable<any>
  {
    let url = environment.apiBaseUrl + "/api/LandingSection";
    return this.httpClient.get(url);
  }

  SaveBriefInfo(data): Observable<any> {
    let url = environment.apiBaseUrl + "/api/LandingSection/SaveBriefInfo";
    return this.httpClient.post(url, data);
  }


  SaveBriefInfoDesc(landingSectionID, briefIntroDesc, briefIntroImagePath, file, filename): Observable<any> {

    let progress = 0;
    const formData = new FormData();
    if (file != null) {
      if (file.length !== 0) {
        formData.append(file.name, file);
      }
    }

    formData.append('landingSectionID', landingSectionID);
    formData.append('briefIntroDesc', briefIntroDesc);
    formData.append('briefIntroImagePath', briefIntroImagePath);

    let url = environment.apiBaseUrl + "/api/LandingSection/SaveBriefInfoDesc";
    const uploadReq = new HttpRequest('POST', url, formData);
    return this.httpClient.request(uploadReq);
  }

  SaveAboutUs(data): Observable<any> {
    let url = environment.apiBaseUrl + "/api/LandingSection/SaveAboutUs";
    return this.httpClient.post(url, data);
  }

  SaveAboutUsDesc(landingSectionID, missionDesc, missionImagePath, file, filename): Observable<any> {

    let progress = 0;
    const formData = new FormData();
    if (file != null) {
      if (file.length !== 0) {
        formData.append(file.name, file);
      }
    }

    formData.append('landingSectionID', landingSectionID);
    formData.append('missionDesc', missionDesc);
    formData.append('missionImagePath', missionImagePath);

    let url = environment.apiBaseUrl + "/api/LandingSection/SaveAboutUsDesc";
    const uploadReq = new HttpRequest('POST', url, formData);
    return this.httpClient.request(uploadReq);
  }
}
