import { Injectable } from '@angular/core';
import { CanLoad, Route} from '@angular/router';
import { MsalUserService } from '../services/msal-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad {

  constructor(private authService: MsalUserService) { }

  canLoad(route: Route): boolean {
    if (this.authService.IsLoggedIn()) {

      if (!route.data.roles || route.data.roles.length === 0) {
        return true;
      }

      let roles = this.authService.getUserRoles();
      if (typeof roles === 'string' && route.data.roles.includes(roles)) {
        return true;
      }
      if (Array.isArray(roles)) {
        for (let i = 0; i < roles.length; i++) {
          if (route.data.roles.includes(roles[i])) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
