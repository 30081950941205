import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { YesNoDialogComponent } from './yes-no.component';
import { YesDialogComponent } from './yes.component';
import { switchMap } from 'rxjs/operators';


@Injectable()
export class DialogHelperService {

  private containerRef: ViewContainerRef;

  constructor(private factoryResolver: ComponentFactoryResolver) {
  }

  setViewContainerRef(vcr: ViewContainerRef) {
    this.containerRef = vcr;
  }

  private createYesNo(showCancelButton?: boolean, showYesNoButton?: boolean): ComponentRef<YesNoDialogComponent> {
    const factory = this.factoryResolver.resolveComponentFactory(YesNoDialogComponent);
    const component = factory.create(this.containerRef.injector);

    if (showCancelButton === false) {
      component.instance.hideCancelButton();
    }
    if (showYesNoButton === false) {
      component.instance.hideYesNoButton();
    }

    this.containerRef.insert(component.hostView);
    return component;
  }
  private createYes(showCancelButton?: boolean, showYesNoButton?: boolean): ComponentRef<YesDialogComponent> {
    const factory = this.factoryResolver.resolveComponentFactory(YesDialogComponent);
    const component = factory.create(this.containerRef.injector);

    if (showCancelButton === false) {
      component.instance.hideCancelButton();
    }
    if (showYesNoButton === false) {
      component.instance.hideYesNoButton();
    }

    this.containerRef.insert(component.hostView);
    return component;
  }

  private removeComponent(componentRef: any) {
    const index = this.containerRef.indexOf(componentRef.hostView);
    this.containerRef.remove(index);
  }

  showYesNoDialog(title: string, message: string, showCancelButton?: boolean,
    showYesNoButton?: boolean, messages?: string[],
    yesButtonName: string = 'Yes', noButtonName: string = 'No',
    cancelButtonName: string = 'Cancel', alignNoButtonToLeft: boolean = false): Observable<number> {
    const componentRef = this.createYesNo(showCancelButton, showYesNoButton);

    const instance = componentRef.instance;
    instance.message = message;
    instance.yesButtonName = yesButtonName;
    instance.noButtonName = noButtonName;
    instance.cancelButtonName = cancelButtonName;
    instance.alignNoButtonToLeft = alignNoButtonToLeft;

    instance.title = title;
    instance.messages = messages;

    return instance.resultFired.pipe
      (switchMap(x => {
        this.removeComponent(componentRef);
        componentRef.destroy();
        return of(x);
      }));
  }
  showYesDialog(title: string, message: string, yammerFeedUrl: string, showCancelButton?: boolean,
    showYesNoButton?: boolean, messages?: string[],
    yesButtonName: string = 'OK', noButtonName: string = 'No',
    cancelButtonName: string = 'Cancel', alignNoButtonToLeft: boolean = false): Observable<number> {
    const componentRef = this.createYes(showCancelButton, showYesNoButton);
    const instance = componentRef.instance;
    instance.message = message;
    instance.yammerFeedUrl = yammerFeedUrl;
    instance.yesButtonName = yesButtonName;
    instance.noButtonName = noButtonName;
    instance.cancelButtonName = cancelButtonName;
    instance.alignNoButtonToLeft = alignNoButtonToLeft;

    instance.title = title;
    instance.messages = messages;

    return instance.resultFired.pipe
      (switchMap(x => {
        this.removeComponent(componentRef);
        componentRef.destroy();
        return of(x);
      }));
  }

}
