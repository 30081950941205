import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private httpData : HttpClient) { }

  getFeedbackData() : Observable<any>{
    let url = environment.apiBaseUrl + "/api/feedback";
    return this.httpData.get(url);
  }

  saveFeedbackData(data):Observable<any>{
    let url = environment.apiBaseUrl + "/api/feedback";
    return this.httpData.post(url,data);
  }

  sendFeedbackEmail(data):Observable<any>{
    let url = environment.apiBaseUrl + "/api/mail";
    return this.httpData.post(url,data);
  }
}
