import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { environment, microsoftURL } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { LandingPageComponent } from './landing-page/landing-page.component';
import { WhatsNewComponent } from './landing-page/whats-new/whats-new.component';

import { MigrationPolicyRepositoryComponent } from './landing-page/migration-policy-repository/migration-policy-repository.component';
import { VirtualWorkspaceComponent } from './landing-page/virtual-workspace/virtual-workspace.component';
import { PolicyDevelopmentAnalysisDataComponent } from './landing-page/policy-development-analysis-data/policy-development-analysis-data.component';
import { KnowledgeSharingComponent } from './landing-page/knowledge-sharing/knowledge-sharing.component';
import { FeedbackComponent } from './landing-page/feedback/feedback.component';
import { AboutUsComponent } from './landing-page/about-us/about-us.component';
import { BriefInfoComponent } from './landing-page/brief-info/brief-info.component';
import { EventsComponent } from './landing-page/events/events.component';
import { SlideShowComponent } from '../app/landing-page/slide-show/slide-show.component';
import { SlideShowService } from './services/slide-show/slide-show.service';
import { ConfirmCloseGuard } from './common/guards/confirm-close-guard.service';
import { DialogHelperService } from './common/modals/dialog-helper.service';
import { YesNoDialogComponent } from './common/modals/yes-no.component';
import { YesDialogComponent } from './common/modals/yes.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProfileComponent } from './header/profile/profile.component';
import { WorkspaceCreateComponent } from './virtual-workspace/workspace-create/workspace-create.component';
/*import { MultiSelectDropdownComponent } from '../app/multi-select-dropdown/multi-select-dropdown.component';*/
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { PagerModule } from '@progress/kendo-angular-pager';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from "@progress/kendo-angular-intl";
import { LearnMoreComponent } from './landing-page/learn-more/learn-more.component';
import { CacheInterceptor } from './common/Interceptors/CacheInterceptor';
import { PopupModule } from '@progress/kendo-angular-popup';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ErrorHandlerService } from './services/logging/error-handler.service';
import { LoggingService } from './services/logging/logging.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { EditorModule } from '@progress/kendo-angular-editor';
import { EnablecookiesComponent } from './enablecookies/enablecookies.component';
import { CarouselModule } from 'primeng/carousel';
import { FeaturedGoodPracticesComponent } from './landing-page/featured-good-practices/featured-good-practices.component';
import { WhatsNewPageComponent } from './landing-page/whats-new-page/whats-new-page.component';







//import { ConfidentialClientApplication }  from "@azure/msal-node";


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect, //isIE ? InteractionType.Popup : InteractionType.Redirect,

    authRequest: {
      scopes: environment.scopeUri,
    },
  };
}

// const cca = new ConfidentialClientApplication({
//   auth: {
//     clientId: '5de2616b-7e47-49a4-8664-6bb66d1f79d6',
//     authority: "https://login.microsoftonline.com/iom.int",
//     clientSecret: '8KS0pKh41ZATXx~.9rk_S6T.BLZYS31n~~'
//   }
// });
// var result = cca.acquireTokenOnBehalfOf({
//   oboAssertion: 'ssdsd',//ssoToken,
//   scopes: ["User.Read"]
// });

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    WhatsNewComponent,
    MigrationPolicyRepositoryComponent,
    VirtualWorkspaceComponent,
    PolicyDevelopmentAnalysisDataComponent,
    KnowledgeSharingComponent,
    FeedbackComponent,
    AboutUsComponent,
    BriefInfoComponent,
    EventsComponent,
    SlideShowComponent,
    YesNoDialogComponent,
    YesDialogComponent,
    HeaderComponent, FooterComponent, ProfileComponent, LearnMoreComponent, EnablecookiesComponent,
    FeaturedGoodPracticesComponent,
    WhatsNewPageComponent
    //,MultiSelectDropdownComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CarouselModule,
    MsalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.uiClienId, // This is your client ID
        authority: 'https://login.microsoftonline.com/' + environment.tenantId, // This is your tenant ID
        redirectUri: environment.redirectUri// This is your redirect URI
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect, //isIE ? InteractionType.Popup : 
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, //isIE ? InteractionType.Popup : 
      protectedResourceMap: new Map([
        [environment.apiBaseUrl, environment.scopeUri],
        [environment.PDADApiBaseUrl, environment.scopeUri],
        [environment.knowledgeSharingApiBaseUrl, environment.scopeUri],
        [environment.analyticsApiBaseUrl, environment.scopeUri],
        [microsoftURL.graphEndpoint, ['user.read']]
      ])
    }),
    NgMultiSelectDropDownModule.forRoot(),
    ScrollViewModule,
    PagerModule,
    DateInputsModule,
      IntlModule,
      PopupModule,
      LayoutModule,
      NotificationModule,
      ProgressBarModule,
      IndicatorsModule,
      TooltipModule,
      EditorModule
  ],
  providers: [
    DialogHelperService,
    LoggingService,
    ConfirmCloseGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    }
  ],
  bootstrap: [AppComponent],
  //exports: [
  //  MultiSelectDropdownComponent
  //]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
