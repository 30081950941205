import { Component, OnInit, OnChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment, redirectionUrl } from '../../environments/environment'
import { LoaderService } from '../common/services/loader.service';
import { NewshighlightsService } from '../services/news-highlights/newshighlights.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})

export class LandingPageComponent implements OnInit {
  dgFileUrl = redirectionUrl.dgfile;
  public landingPageGoodPractices = environment.landingPageGoodPractices;
  public landingPageInnovativeInitiative = environment.landingPageInnovativeInitiative;
  public landingPageKnowledgePlatforms = environment.landingPageKnowledgePlatforms;
  public landingNewsHighlights = environment.ikmspsite;
  news_highlights;
  responsiveOptions;

  constructor(private router: Router, private loaderService: LoaderService, private NewshighlightsService: NewshighlightsService) {
    this.bindRouterEvent();
    this.responsiveOptions = [{
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 1
    }];
  }

  ngOnInit(): void {
    /*this.NewshighlightsService.getNewshighlightsData().subscribe(data=>  {
      this.news_highlights = data;
      console.log(this.news_highlights);
    });*/
    this.DisplayNHWorkaround();


  }


  bindRouterEvent() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let tree = this.router.parseUrl(this.router.url);
        this.loaderService.showLoader();
        setTimeout(() => {
          if (tree.fragment) {
            let element = document.querySelector("#" + tree.fragment);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
          }
        }, 200);
        this.loaderService.hideLoader();
      }
    });
  }

  scrollToFragment() { }

  onDGFilesNavigation() {
    window.open(this.dgFileUrl, '_blank');
  }

  onIASiteNavigation() {
    window.open("https://iomint.sharepoint.com/sites/InnovationAwards", '_blank');
  }

  onKMGuideNavigation() {
    window.open("https://iomint.sharepoint.com/teams/InnovationandKnowledgeManagementWorkingGroup/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FInnovationandKnowledgeManagementWorkingGroup%2FShared%20Documents%2FGeneral%20KMI%20Working%20Group%2FIOM%20Guide%20to%20Knowledge%20Platforms&p=true&ct=1693280452569&or=Teams%2DHL&ga=1&LOF=1", '_blank');
  }

  //workaround for news highlights
  DisplayNHWorkaround(){
    let nhData:any = [
      {
        subtitle:'Objective 1',
        subtitlecolor:'#F8B53F',
        title: 'Saving lives and protecting people on the move', 
        description:'As global displacement reaches record levels, IOM responds to the most challenging crisis response contexts in the world by putting the safety, dignity and protection of people first. This entails collecting and using data and insights on the mobility, capabilities, vulnerabilities and needs of displaced, host and mobile population, to provide tailored responses.', 
        imageURL:'../../assets/images/drivingsoltns_01.jpg',//'../../assets/images/savinglives_01.jpg', 
        url:'../knowledge-sharing/good-policy-practices/Good%20Practices%20&%20Lessons%20Learned?tm=188',
        readmoreURL:'https://www.iom.int/objective1-saving-lives-and-protecting-people-move'
      },
      {
        subtitle:'Objective 2',
        subtitlecolor:'#62B5AF',
        title: 'Driving solutions to displacement', 
        description:'IOM goes beyond simply responding to crises to developing proactive and anticipatory strategies in addressing global displacement challenges. By leveraging migration expertise and private sector technology, IOM aims to develop data-driven capabilities to foresee migration patterns, collaborating with stakeholders to work towards common goals, including the reduction of risks and impacts of climate change and environmental degradation.', 
        imageURL:'../../assets/images/savinglives_02.jpg',//'../../assets/images/drivingsoltns_01.jpg', 
        url:'../knowledge-sharing/good-policy-practices/Good%20Practices%20&%20Lessons%20Learned?tm=187',
        readmoreURL:'https://www.iom.int/objective2-driving-solutions-displacement'
      },
      {
        subtitle:'Objective 3',
        subtitlecolor:'#F46029',
        title: 'Facilitating pathways for regular migration', 
        description:'IOM assists states in establishing and expanding regular migration pathways while reducing irregular migration. Prioritizing whole-of-government, whole-of-society approaches, IOM safely connects people, goods, services, knowledge and innovation.', 
        imageURL:'../../assets/images/pathways_01.jpg', 
        url:'../knowledge-sharing/good-policy-practices/Good%20Practices%20&%20Lessons%20Learned?tm=186',
        readmoreURL:'https://www.iom.int/objective3-facilitating-pathways-regular-migration'
      }
    ];

    this.news_highlights = nhData;
  }

  onClickReadMoreURL(url:any, target:string){
    window.open(url, target);
  }

}
