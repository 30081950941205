import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogHelperService } from '../modals/dialog-helper.service';
import { DialogResult } from '../modals/yes-no.component';

export interface IDeactivateComponent {
  hasChanges: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class ConfirmCloseGuard implements CanDeactivate<IDeactivateComponent>
{
  component: Object;
  route: ActivatedRouteSnapshot;

  confirmCloseMessage = "You have unsaved changes! If you leave, your changes will be lost.";
  confirmCloseTitle = "Confirm";

  constructor(private dialogHelperService: DialogHelperService) {
  }


  canDeactivate(component: IDeactivateComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (component.hasChanges()) {
      return this.dialogHelperService
        .showYesNoDialog(
          this.confirmCloseTitle,
          this.confirmCloseMessage,
          false,
          true,
          [],
          "Yes",
          "No",
          "Cancel",
          false
        )
        .pipe(map(x => x == DialogResult.YES));
    } else {
      return true;
    }
  }

}
