import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { delay } from 'rxjs/operators';
import { SlideShowService } from '../../../app/services/slide-show/slide-show.service';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.css']
})
export class SlideShowComponent implements OnInit, AfterViewInit{
  @ViewChild("sv") private scrollView;
  public slides: any[];
  viewchecked = false;
  public appendToUrl = environment.azblobSecurityUrl;
  public endless = false;
  private interval;
  constructor(private slideShowService: SlideShowService) { }

  ngOnInit(): void {
    this.getSlides();
  }
  public ngAfterViewInit() {
    this.scrollView.endless = true;
    this.interval = setInterval(() => {     
        this.scrollView.next();
    }, 2000);
  }

   
  getSlides() : any
  {
     this.slideShowService.getSlideShowImages().subscribe(data =>
      {
       this.slides = data;
     })
  }
  
}
