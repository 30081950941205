import { Component, HostListener, OnInit } from "@angular/core";
import { VirtualWorkspaceSection } from "../../../app/constants/constants";
import { KnowledgeSharingSection } from '../../../app/constants/constants';
import { Router } from "@angular/router";
import { WorkspaceListService } from "src/app/services/workspace-list/workspace-list.service";
import { Align } from "@progress/kendo-angular-popup";
@Component({
  selector: "app-virtual-workspace",
  templateUrl: "./virtual-workspace.component.html",
  styleUrls: ["./virtual-workspace.component.css"],
})
  //Constructor
export class VirtualWorkspaceComponent implements OnInit {
  sectionConstant: any;
  sectionConstantKnowledgeSharing
  public searchText: string = "";
  public searchData = [];
  public searchFilterData = [];
  public showPopup: boolean = false;
  public selectedWorkspace :any;
  public loading = false;

  private anchorAlign: Align = { horizontal: "left", vertical: "bottom" };
  private popupAlign: Align = { horizontal: "left", vertical: "top" };

  constructor(private router: Router, private workspaceListService: WorkspaceListService) {
    this.sectionConstant = VirtualWorkspaceSection;
    this.sectionConstantKnowledgeSharing = KnowledgeSharingSection;
  }

  ngOnInit(): void {

    this.workspaceListService
      .getWorkspaceList()
      .subscribe((data) => {
        this.searchData = data;
      });

  }

  keyDown(event: KeyboardEvent) {
    this.searchText = this.searchText.trim();
    if (this.searchText == "") event.preventDefault();
  }
  
  public displayPopup() {
    this.showPopup = true;
    this.selectedWorkspace = null;
    if (this.searchText.length > 2) {
      this.getWorkspaceListBySearch(this.searchText);
    } else {
      this.searchFilterData = [];
    }
  }

  selectWorkspace(workspace){
    this.selectedWorkspace = workspace;
    this.searchText = workspace.policyname;
    document.getElementById("searchWorkspace").focus();
  }

  getWorkspaceListBySearch(searchtext) {
    this.loading = true;
    this.searchFilterData = this.searchData.filter(c =>c.policyname.toLowerCase().includes(searchtext.toLowerCase()));
    this.loading = false;
  }

  loadWorkspaceData() {
    if (this.searchText != "")
      this.router.navigate([
        "../virtual-workspace/find-workspace",
        this.sectionConstant.Find_Workspace,
        this.searchText,
      ]);
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: any): void {  
    this.showPopup = false;
  } 
}
