import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private httpData: HttpClient) { }


  getRegion():Observable<any>{
    let url =environment.PDADApiBaseUrl + '/api/Region';
    return this.httpData.get(url);
  }


}
